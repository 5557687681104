import React, { useContext, useMemo } from 'react'
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { Item } from '../../content/headerData.interface'
import { ConfCtx } from '../../context/conf'
import { customersObj } from '../../../customer-config'
import { useTranslation } from 'next-i18next'

export const ShopListStraighttalk = ({
  headerData,
  isMobile,
  index,
}: {
  headerData: Item[]
  isMobile: boolean
  index: number
}) => {
  const { retailer } = useContext(ConfCtx)
  const { t } = useTranslation('header')
  const filteredHeaderData = useMemo(() => {
    return (headerData.map((itemTitle) => {
      if (itemTitle.title.toLowerCase() === t('plansServices').toLowerCase()) {
        const filteredSubTitles = itemTitle.children.map((subTitle) => {
          if (subTitle.title.toLowerCase() === t('devicePlans').toLowerCase()) {
            return ({
              ...subTitle,
              children: subTitle.children.filter((item) => item.title.toLowerCase() !== t('alertPlans').toLowerCase()),
            })
          }
          return subTitle
        })
        return ({
          ...itemTitle,
          children: filteredSubTitles,
        })
      }
      return itemTitle
    }))
  }, [headerData])
 
  const iconsArray = [
    {
      variants: ['accessories', 'ACCESORIOS'],
      src: 'accessories',
    },
    {
      variants: ['Deals', 'Ofertas'],
      src: 'deals',
    },
    {
      variants: ['Device Plans', 'PLANES PARA DISPOSITIVOS'],
      src: 'devicePlans',
    },
    {
      variants: ['Devices', 'DISPOSITIVOS'],
      src: 'devices',
    },
    {
      variants: ['HANDSET PROTECTION', 'PROTECCIÓN DEL TELÉFONO'],
      src: 'handsetProtection',
    },
    {
      variants: ['More Options', 'MÁS OPCIONES'],
      src: 'moreOptions',
    },
    {
      variants: ['Phones', 'Teléfonos'],
      src: 'phones',
    },
    {
      variants: ['Refill', 'Recargar'],
      src: 'refill',
    },
    {
      variants: ['Service Plans', 'PLANES DE SERVICIO'],
      src: 'servicePlans',
    },
    {
      variants: ['STRAIGHT TALK EXTRAS'],
      src: 'extras',
    },
  ]

  const findImage = (name: string) => {
    const currentIcon = iconsArray.find((item) =>
      item.variants
        .map((i) => i.toLowerCase())
        .includes(name.toLowerCase().trim(), 0),
    )
    return currentIcon?.src
  }

  return (
    <Box>
      <Flex
        pt={[3, 3, 8]}
        px={[3, 3, 8]}
        pl={[0, 0, 4, 4, 4]}
        gap={3}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        flexDir={['column-reverse', 'column-reverse', 'row']}
        w={['auto', 'auto', 'auto', '3xl', '5xl', '6xl']}
      >
        <Flex flexDir={['column', 'column', 'row']} gap={[0, 0, 0, 0, 14, 14]}>
          {!isMobile && (
            <Flex flexDir={'column'}>
              <Heading tabIndex={0} fontSize={44} color={'black.400'}>
                {filteredHeaderData[index].title}
              </Heading>
              <Text tabIndex={0} w={64} fontSize={'sm'} color={'#b2b2b2'}>
                {filteredHeaderData[index].description}
              </Text>
            </Flex>
          )}
          {filteredHeaderData &&
            filteredHeaderData[index].children.map((item, indexHeader) => (
              <Container
                key={indexHeader}
                variant='shopList'
                border={0}
                borderTop={0}
                pb={[0]}
                pl={[0, 0, 4, 4, 4]}
                _last={{
                  borderBottom: '0px solid',
                }}
              >
                {!isMobile && (
                  <Image
                    mb={6}
                    alt={item.title}
                    width={20}
                    src={`/assets/${retailer}/${findImage(item.title)}.png`}
                  />
                )}
                <Heading
                  tabIndex={0}
                  variant={'shopListSmall'}
                  w={[28, 28, '100%']}
                  mb={[0, 4]}
                  color={'gray.500'}
                >
                  {item.title.toUpperCase()}
                </Heading>
                <Container
                  px={0}
                  mx={0}
                  ml={[-47, -47, 0, 0, 0]}
                  mt={[10, 10, 10, 10, 3]}
                  pb={4}
                >
                  <UnorderedList listStyleType={'none'} ms={'0'}>
                    {item.children.map((child, i) => (
                      <ListItem
                        aria-label={child.title}
                        key={i}
                        pb={[10, 10, 5, 5, 5]}
                        color={'black.400'}
                        fontSize={13}
                      >
                        <Link
                          tabIndex={-1}
                          href={`${customersObj[retailer].headerExternalLinkBase}${child.url}`}
                        >
                          <Button
                            tabIndex={0}
                            p={0}
                            whiteSpace='normal'
                            height='auto'
                            width='auto'
                            minWidth='auto'
                            textAlign='left'
                            fontWeight='inherit'
                            fontSize='inherit'
                            bg='transparent'
                            variant='focusedButton' 
                            _focus={{
                              boxShadow: 'none',
                            }}
                          >
                            {child.title}
                          </Button>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Container>
              </Container>
            ))}
        </Flex>
        <Flex
          key={1}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          flexDirection={'column'}
          pos='relative'
        >
          <Heading fontSize={26} as='h3'>
            {filteredHeaderData[0].quickLinksHeading}
          </Heading>
        </Flex>
      </Flex>
    </Box>
  )
}

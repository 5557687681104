import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react'
import nextI18nextConfig from '../../../next-i18next.config.js'
import { TITLES } from '../../content/headerTitles'
import LanguageSwitchLink from '../languageSwitchLink/languageSwitchLink.jsx'
import { ShopAccordion } from '../shopList/shopList'
import { HeaderList } from '../header/header'
import { useTranslation } from 'next-i18next'
import { useContext } from 'react'
import { ConfCtx } from '../../context/conf'
import { customersObj } from '../../../customer-config'

interface MobileHeaderProps {
  headerList: HeaderList
  currentLocale: any
  isEng: boolean
}

export const MobileHeader = ({
  headerList,
  currentLocale,
  isEng,
}: MobileHeaderProps) => {
  const { t } = useTranslation('header')
  const { retailer } = useContext(ConfCtx)
  const currentLang = isEng ? 'en' : 'es'

  return (
    <>
      {!customersObj[retailer].specialHeaderAndFooter ? (
        <Container
          variant={'mobileHeader'}
          px={0}
          borderTop='1px solid'
          borderColor='gray.200'
        >
          {headerList.defaultItems?.map((item, index) => (
            <Link
              href={item.linkUrl}
              key={index}
              target='_blank'
              aria-label={item.linkUrl}
              variant='mobileNavLink'
              py={4}
              pl={10}
              textDecoration='none'
              tabIndex={0}
            >
              {item.linkText}
            </Link>
          ))}
        </Container>
      ) : (
        headerList.item.map((item, i) =>
          TITLES.includes(item.title) ? (
            <Accordion allowToggle key={i}>
              <AccordionItem border='none' textColor={'black'}>
                {!(item.title.includes('verizon') && retailer !== 'tbv') && (
                  <AccordionButton
                    _expanded={{
                      bg:
                        retailer !== 'tbv'
                          ? 'headerHelmet.bg'
                          : 'headerHelmet.mobileBg',
                      color:
                        retailer !== 'tbv'
                          ? 'headerHelmet.text'
                          : 'headerHelmet.mobileText',
                    }}
                    tabIndex={0}
                    fontWeight={'bold'}
                    justifyContent={'space-between'}
                    py={4}
                    px={10}
                    border='none'
                    _focus={{
                      boxShadow: 'none',
                    }}
                  >
                    {item.title}
                    <AccordionIcon />
                  </AccordionButton>
                )}
                <AccordionPanel>
                  {i === 0 ? (
                    <ShopAccordion
                      headerData={headerList.item}
                      isMobile={true}
                    />
                  ) : (
                    <Flex alignItems={'center'} flexDirection={'column'}>
                      <Heading as={'h3'} variant={'refillHeading'} tabIndex={0}>
                        {item.flyoutHeader}
                      </Heading>
                      <Flex
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        gap={3}
                      >
                        {item.children.map((child, index) => (
                          <Link key={index} href={child.url} fontSize={'sm'} tabIndex={0}>
                            {child.title}
                          </Link>
                        ))}
                      </Flex>
                    </Flex>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ) : (
            <Container display={'flex'} flexDirection={'column'} p={0} key={i}>
              <Text p={4} pl={10} key={i} textAlign='left' variant='navText'>
                <Link aria-label={item.title} href={item.url} color={'black'}>
                  {item.title}
                </Link>
              </Text>
            </Container>
          ),
        )
      )}
      {customersObj[retailer].specialHeaderAndFooter && (
        <Container
          variant={'mobileHeader'}
          px={0}
          borderTop='1px solid'
          borderColor='gray.200'
        >
          {headerList.navigationLinks?.map((link, index) => (
            <Link
              href={
                link.linkUrl === '#customerlogin'
                  ? customersObj[retailer].loginLink[currentLang]
                  : link.linkUrl
              }
              target='_blank'
              aria-label={link.linkAltText}
              key={index}
              variant='mobileNavLink'
              py={4}
              pl={10}
              textDecoration='none'
            >
              {link.linkText}
            </Link>
          ))}
        </Container>
      )}
      <Flex flexDir='column' color={'black'} bgColor='gray.100' py={5}>
        <Text variant='mobileHeaderList' px={10}>
          {nextI18nextConfig.i18n.locales.map((locale) => {
            if (locale === currentLocale) return null
            return <LanguageSwitchLink locale={locale} key={locale} />
          })}
        </Text>
        {customersObj[retailer].specialHeaderAndFooter && (
          <Box>
            {headerList.links.map((el, i) => (
              <Text variant='mobileHeaderList' key={i} px={10}>
                <a
                  aria-label={el.linkAltText}
                  href={`${customersObj[retailer].headerExternalLinkBase}/${el.linkUrl}`}
                  rel={el.doNotFollowLink}
                >
                  {el.linkText}
                </a>
              </Text>
            ))}
            <Text variant='mobileHeaderList' px={10}>
              <a
                href={`${customersObj[retailer].headerExternalLinkBase}/${
                  !isEng ? currentLocale : ''
                }`}
                target='_blank'
                rel='noreferrer'
              >
                {t(`goTo.${retailer}`)}
              </a>
            </Text>
          </Box>
        )}
      </Flex>
    </>
  )
}

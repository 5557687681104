export const meta = ({
  value,
  retailer,
}: {
  value?: string
  retailer?: string
}) => ({
  home: {
    title: `Customer Support, Tutorials and FAQs - ${retailer}`,
    description: `Find out how to change your plan, add a new line, get activation assistance, learn about our 5G, and review other common ${retailer} help topics.`,
  },
  brand: {
    title: `Find Support & Tutorials for Your Prepaid Phone  - ${retailer}`,
    description: `Find out how to change your plan, add a new line, get activation assistance, learn about our 5G, and review other common ${retailer} help topics.`,
  },
  model: {
    title: `Tutorials & Guides for Your ${value} phone - ${retailer}`,
    description: `Select the model of your ${value} phone to get help and browse tutorials, FAQs and Guides`,
  },
  phone: {
    title: `Knowledge Base for ${value} - ${retailer}`,
    description: `Browse ${retailer} Knowledge Base, tutorials and FAQs for your ${value}`,
  },
  topic: {
    title: `Get Customer Support on Common Topics & Questions - ${retailer}`,
    description: `Browse common support topics for your prepaid phone. Navigate topics such as account management, phones, services, airtime, and more at ${retailer}.`,
  },
})

import type { NextPage } from 'next'
import { Contact } from '../../src/components/contact/contact'
import { Search } from '../../src/components/search/Search'
import { Footer } from '../../src/components/footer/footer'
import { Header } from '../../src/components/header/header'
import { HomePageNav } from '../../src/components/homePageNav/homePageNav'
import { PopularFAQ } from '../../src/content/popularFAQ.interface'
import { getStaticPaths, makeStaticProps } from '../../src/lib/getStatic.js'
import { FaqList } from '../../src/components/faqList/faqList'
import { PageMeta } from '../../src/components/pageMeta/PageMeta'
import { Language } from '../../src/content/locale.interface'
import { meta } from '../../src/content/meta'
import { CustomerSwitcher } from '../../src/components/customer-switch'
import { customersObj } from '../../customer-config'
import { useContext, useEffect, useMemo, useState } from 'react'
import { ConfCtx } from '../../src/context/conf'
import { retailersInfo } from '../../src/content/retailersInfo'
import { client } from '../../src/services/kms.instance'
import { Faq } from '../../src/services/kms'
import { Recommended } from '../../src/content/recommendations.interface'
import { RecombeeClientConfiguration } from '../../src/lib/recombeeClientConfiguration'
import { postAnalytics } from '../../src/analytics'
import { useAnalyticsPathParam } from '../../utils/hooks/useAnalyticsPathParam'
import { usePreviousRoute } from '../../utils/hooks/usePreviousRoute'

type PopularFAQList = {
  topics: Array<{
    name: string
    icon: string
    children: Array<{
      name: string
      children: Array<{ name: string; val: string }>
    }>
  }>
  locale: Language
  faq: [
    { name: string; children: [{ name: string; children: [] }]; icon: string },
  ]
  popularFaqs: PopularFAQ[]
}

const Homepage: NextPage<PopularFAQList> = ({
  locale,
}) => {
  const [recommendationsFaqs, setRecommendationsFaqs] = useState<Faq[] | null>(null)
  const { retailer } = useContext(ConfCtx)
  const referrer = usePreviousRoute()
  const pagePath = useAnalyticsPathParam()
  const retailerTitle = useMemo(
    () => retailersInfo.find((i) => i.retailer === retailer)?.title,
    [retailer],
  )
  const customer = customersObj[retailer]
  const clientConfiguration = RecombeeClientConfiguration()

  const getFaqsBySlug = async (recomms: { id: string, values: Faq }[]) => {
    try {
      const res = await Promise.all(recomms.map(({ values: { slug } }): Promise<Faq> => {
        if (!slug) throw new Error
        return new Promise(async (resolve, reject) => {
          try {
            const resPromise = await client.faqBySlug({
              customer: customer.contentCustomerId,
              slug,
              language: locale,
            })
            resolve(resPromise)
          } catch (err) {
            reject(err)
          }
        })
      },
      ))
      setRecommendationsFaqs(res)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (typeof window === 'undefined' || !window.recombee) return
    new window.recombee.ApiClient(...clientConfiguration)
      .send(new window.recombee.RecommendItemsToUser('random_user', 5, {
        scenario: 'FAQs',
        returnProperties: true,
        cascadeCreate: true,
      }),
      (err: string, recommended: Recommended) => {
        if (err) {
          console.error(err)
          return
        }
        getFaqsBySlug(recommended.recomms)
      },
      )
  }, [])

  useEffect(() => {
    postAnalytics({
      data: {
        referrer_url: referrer ? `${location.origin}${referrer}` : null,
        page_title: pagePath,
        event_category: 'pageviews',
      },
      category: 'pageviews',
    })
  })

  return (
    <>
      <PageMeta
        title={meta({ retailer: retailerTitle }).home.title}
        description={meta({ retailer: retailerTitle }).home.description}
      />
      <main>
        <CustomerSwitcher />
        <Header />
        <Search defaultInput={''}/>
        <HomePageNav />
        {recommendationsFaqs && recommendationsFaqs.length > 0 && <FaqList faqs={recommendationsFaqs} />}
        {customersObj[retailer].contactSection && (
          <Contact bgColor='contact.bg' />
        )}
        <Footer />
      </main>
    </>
  )
}

const getStaticProps = makeStaticProps(
  ['header', 'footer', 'common'],
  async ({ params: { locale } }: { params: { locale: Language } }) => {
    return {
      props: {
        locale,
      },
    }
  },
)

export { getStaticPaths, getStaticProps }

export default Homepage

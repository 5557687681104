import { Box, Container, Heading, Link, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { homePageNav } from '../../content/homePageNav'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { openCloseChat } from '../../services'
import {
  FaqIcon,
  KnowledgeBaseIcon,
  PhoneIcon,
} from '../../styles/components/customIcons'
import { HomePageNav as HomePageTypes } from '../../content/homePageNav.interface'
import { ConfCtx } from '../../context/conf'

const getIcons = (type: HomePageTypes['type']) => {
  switch (type) {
    case 'phones':
      return <PhoneIcon />
    case 'chat':
      return <KnowledgeBaseIcon />
    case 'faq':
      return <FaqIcon />
    default:
      break
  }
}

export const HomePageNav = () => {
  const { t } = useTranslation('common')
  const { asPath } = useRouter()
  const { retailer } = useContext(ConfCtx)
  const [path, query = ''] = asPath.split('?')

  return (
    <Container variant='KnowledgeBoxContainer'>
      {homePageNav(retailer).map((el, i) => {
        if (el.type === 'chat') {
          return (
            <Container
              as={'button'}
              aria-label={t(el.title)}
              variant='knowledgeBox'
              onClick={() => openCloseChat('show')}
              key={i}
              tabIndex={0}
            >
              <Box w={[8, 12, 16]} h={[8, 12, 16]} pos='relative'>
                {getIcons(el.type)}
              </Box>
              <Heading as='h3' variant='knowledgeHeading' tabIndex={0}>
                {t(el.title)}
              </Heading>
              <Text fontSize={['xs', 'md', 'xl']} textAlign={'left'} tabIndex={0}>
                {t(el.desc)}
              </Text>
            </Container>
          )
        } else {
          return (
            <Container variant='knowledgeBox' key={i}>
              <Link
                aria-label={t(el.title)}
                tabIndex={0}
                href={path + el.path + '/?' + query}
                _hover={{ textDecoration: 'none' }}
              >
                <Box w={[8, 12, 16]} h={[8, 12, 16]} pos='relative'>
                  {getIcons(el.type)}
                </Box>
                <Heading as='h3' variant='knowledgeHeading' tabIndex={0}>
                  {t(el.title)}
                </Heading>
                <Text fontSize={['xs', 'md', 'xl']} tabIndex={0}>
                  {t(el.desc)}
                </Text>
              </Link>
            </Container>
          )
        }
      })}
    </Container>
  )
}

import { useContext, useRef } from 'react'
import {
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useOutsideClick,
  AccordionIcon,
  Box,
  Flex,
  useTheme,
} from '@chakra-ui/react'
import { theme } from '../../styles/theme'
import { ConfCtx } from '../../context/conf'
import { customersObj } from '../../../customer-config'

interface HeaderAccordionInterface {
  onHandleToggleAccordion: (index: number) => void
  collapsedAccordion: { index: number; isCollapsed: boolean }
  children: React.ReactNode
  panelRef: any
  title: string
  index: number
  display: string
}

export const HeaderAccordion = ({
  onHandleToggleAccordion,
  collapsedAccordion,
  children,
  panelRef,
  title,
  index,
  display,
}: HeaderAccordionInterface) => {
  const ref = useRef(null)
  const isShop = index === 0
  const { retailer } = useContext(ConfCtx)
  const themes: any = { ...theme(retailer), ...useTheme() }
  const { headerStyles } = themes
  const styleConfig = customersObj[retailer]?.headerConfig

  useOutsideClick({
    ref: panelRef,
    enabled: document.documentElement.offsetWidth > 991.98,
    handler: () => onHandleToggleAccordion(-1),
  })

  return (
    <Accordion
      display={display}
      allowToggle
      reduceMotion={true}
      index={
        !collapsedAccordion.isCollapsed && collapsedAccordion.index === index
          ? 0
          : -1
      }
    >
      <AccordionItem
        border='none'
        p={0}
        ml={
          styleConfig?.separatedLogo
            ? ['0', '0', '0', '0', '2rem']
            : '0'
          }
        position={'relative'}
        {...headerStyles?.headerAccordion?.accordionItem}
      >
        {({ isExpanded }: { isExpanded: boolean }) => (
          <>
            <AccordionButton
              onClick={() => onHandleToggleAccordion(index)}
              _hover={{
                background: 'none',
                textDecoration: 'underline',
              }}
              _focus={{ boxShadow: 'none' }}
              _expanded={{
                border: '1px',
                borderColor: 'text.header',
              }}
              py={0}
              px={3}
              ref={ref}
              fontSize={['sm', 'sm', 'sm', 'sm', 'md', 'md']}
              outline= '1px solid transparent'
              outlineOffset= '0'
              border= '2px solid transparent'
              borderRadius= '5px'
              _focusVisible= {{
                outlineColor: '#FFFFFF',
                borderColor: '#000000',
              }}
              {...headerStyles?.headerAccordion?.accordionButton}
            >
              <Flex
                position={'relative'}
                flexDir={'column'}
                {...headerStyles?.headerAccordion?.flexContainer}
              >
                <Text
                  variant={
                    isExpanded
                      ? 'activeAccordionButton'
                      : 'inactiveAccordionButton'
                  }
                  {...headerStyles?.headerAccordion?.text}
                >
                  {title}
                  {title && <AccordionIcon />}
                </Text>
                <Box {...headerStyles?.headerAccordion?.box} />
              </Flex>
            </AccordionButton>
            <AccordionPanel
              boxShadow={'1px 10px 15px 1px #b8b8b8'}
              borderColor={'gray.400'}
              w={'auto'}
              backgroundColor={'gray.100'}
              left={isShop ? -200 : -83}
              position={'absolute'}
              ml={'auto'}
              mr={'auto'}
              top={53}
              textColor={'black'}
              zIndex={99}
              {...headerStyles?.headerAccordion?.accordionPanel}
            >
              {children}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}

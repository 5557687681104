import {
  Box,
  Flex,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useTheme,
  VStack,
} from '@chakra-ui/react'
import Image from 'next/image'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { FooterList } from '../footerList/footerList'
import { exportableLoader } from '../../image-loader'
import { getFooterItems, openCloseChat } from '../../services'
import { FooterItems } from '../../content/footerItems.interface'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import nextI18nextConfig from '../../../next-i18next.config.js'
import { useTranslation } from 'next-i18next'
import { themes as theme } from '../../styles/themes'
import {
  Blog,
  Facebook,
  Instagram,
  Twitter,
  Youtube,
} from '../../styles/components/customIcons'
import { retailersInfo } from '../../content/retailersInfo'
import { ConfCtx } from '../../context/conf'
import { SignForm } from './signForm'
import { customersObj } from '../../../customer-config'

type FooterProps = {
  paddingRight?: string | (string | number)[]
}
type EditedFooterItems2 = {
  linkText?: string,
  linkUrl?: string, 
  linkIcon?: string,
}[]
type FooterPrivacyIndex = 'privacy_policy' | 'privacy_central'

export const Footer: FC<FooterProps> = ({ paddingRight = '1rem' }) => {
  const { t } = useTranslation('footer')
  const { query } = useRouter()
  const { retailer } = useContext(ConfCtx)
  const themes = { ...theme(retailer), ...useTheme() }
  const { footerStyles, headerStyles } = themes
  const isMobile = (typeof window !== 'undefined') && (document.documentElement.clientWidth < 992)

  const currentLocale: any =
    query.locale || nextI18nextConfig.i18n.defaultLocale

  const [footerData, setFooterData] = useState<FooterItems>({
    footerItems1: [],
    footerItems2: [],
    shopLinks: [],
    currentCustomers: [],
    support: [
      {
        linkUrl: `/${query.locale}/brands`,
        linkText: t('select_phone_model'),
        withoutBlank: true,
      },
      {
        linkUrl: `/${query.locale}/topics`,
        linkText: t('browse_knowledge_base'),
        withoutBlank: true,
      },
      {
        linkUrl: '',
        linkText: t('needHelp'),
        onClick: () => openCloseChat('show'),
        withoutBlank: true,
      },
    ],
    aboutTracfone: [],
    footerInc: { text: '' },
    checkBalance: {
      en: {
        text: '',
      },
      es: {
        text: '',
      },
    },
  })

  const iconData = useMemo(() => {
    const nameRetailer = retailersInfo.find(
      (item) => item.retailer === retailer,
    )?.title
    const socialLinks = retailersInfo.find((item) => item.retailer === retailer)
      ?.link as {
      [key: string]: string
    }
    const socialLinksArray = []
    for (const key in socialLinks) {
      socialLinksArray.push({
        icon: key,
        href: socialLinks[key],
        linkAltText: `Visit ${nameRetailer} ${key} page`,
      })
    }
    return socialLinksArray
  }, [retailer])

  const getIcons = (type: string) => {
    switch (type) {
      case 'facebook':
        return <Facebook />
      case 'instagram':
        return <Instagram />
      case 'twitter':
        return <Twitter />
      case 'youtube':
        return <Youtube />
      case 'blog':
        return <Blog />
      default:
        break
    }
  }

  const retrieveFooterData = async () => {
    const data = await getFooterItems(
      currentLocale,
      customersObj[retailer].headerFooterUrl,
    )
    const footerGroup = await data.items
    setFooterData({
      ...footerData,
      footerItems1:
        footerGroup.columncontrol.items['col-par-0'][':items'].columncontrol
          .items['col-par-0'][':items'].multilinks.links,
      footerItems2:
        [
          ...footerGroup.columncontrol.items['col-par-0'][':items'].columncontrol
            .items['col-par-1'][':items'].multilinks.links,
        ],
      shopLinks:
        footerGroup.columncontrol.items['col-par-1'][':items'].multilinks.links,
      currentCustomers:
        footerGroup.columncontrol.items['col-par-1'][':items']
          .multilinks_913086938.links,
      aboutTracfone:
        footerGroup.columncontrol.items['col-par-1'][':items']
          .multilinks_1741610793.links,
      footerInc: footerGroup.rte,
      checkBalance: {
        en: footerGroup.columncontrol.items['col-par-0'][':items']
          .rte_1011352230,
        es:
          footerGroup.columncontrol.items['col-par-0'][':items']
            .rte_1689038636 ||
          footerGroup.columncontrol.items['col-par-0'][':items'].rte_87162648,
      },
    })
  }
  const shortFooterItems = [
    customersObj[retailer].footerConfig.footerAdditionalLinks.privacy_policy,
    customersObj[retailer].footerConfig.footerAdditionalLinks.privacy_central,
    customersObj[retailer].footerConfig.footerAdditionalLinks.privacy_choices,
  ]
  const findIndexOfArray = (
    array: EditedFooterItems2,
    en: string,
    es: string,
  ) => {
    return array.findIndex(
      (el) =>
        el.linkText?.trim().toLowerCase() === en ||
           el.linkText?.trim().toLowerCase() === es,
    )
  }

  const replaceElementOfArray = (
    array: EditedFooterItems2,
    indexToReplace: number,
    indexOfReplace: FooterPrivacyIndex,
  ) => {
    if (indexToReplace !== -1) {
      array.splice(indexToReplace, 1, {
        linkText:
              customersObj[retailer].footerConfig.footerAdditionalLinks[
                indexOfReplace
              ].linkText[currentLocale as 'en' | 'es'],
        linkUrl:
              customersObj[retailer].footerConfig.footerAdditionalLinks[
                indexOfReplace
              ].linkUrl[currentLocale as 'en' | 'es'],
      })
    }
  }

  const removeElementOfArray = (
    array: EditedFooterItems2,
    indexToRemove: number,
  ) => {
    if (indexToRemove !== -1) {
      array.splice(indexToRemove, 1)
    }
  }

  const pushElementInArray = (
    array: EditedFooterItems2,
    indexOfReplace: 'privacy_choices',
  ) => {
    array.push({
      linkText:
          customersObj[retailer].footerConfig.footerAdditionalLinks[indexOfReplace]
            ?.linkText[currentLocale as 'en' | 'es'],
      linkUrl:
          customersObj[retailer].footerConfig.footerAdditionalLinks[indexOfReplace]
            ?.linkUrl[currentLocale as 'en' | 'es'],
      linkIcon:
          customersObj[retailer].footerConfig.footerAdditionalLinks[indexOfReplace]
            ?.linkIcon,
    })
  }

  const editFooterItems2 = () => {
    const linksToReplace = [{
      en: 'privacy policy',
      es: 'política de privacidad',
      configPropToReplace: 'privacy_policy',
    }, {
      en: 'california privacy policy',
      es: 'política de privacidad de california',
      configPropToReplace: 'privacy_central',
    }]
    const linksToRemove = [{
      en: 'do not sell my personal information', 
      es: 'no venda mi información personal',
    }]
    const linkToAdd = 'privacy_choices'

    const newFooterLinks = [...footerData.footerItems2]

    linksToRemove.forEach((item) => {
      const index = findIndexOfArray(
        newFooterLinks,
        item.en,
        item.es,
      )
      removeElementOfArray(newFooterLinks, index)
    })

    linksToReplace.forEach((item) => {
      const index = findIndexOfArray(
        newFooterLinks,
        item.en,
        item.es,
      )
      replaceElementOfArray(newFooterLinks, index, item.configPropToReplace as FooterPrivacyIndex)
    })

    pushElementInArray(newFooterLinks, linkToAdd)

    return newFooterLinks
  }

  useEffect(() => {
    retrieveFooterData()
  }, [currentLocale, retailer])

  return (
    <>
      <Flex
        align='center'
        flexDir='column'
        pt={[8, 8, 8, 66]}
        pr={paddingRight}
        w='full'
        bg={customersObj[retailer]?.footerConfig?.backgroundColor || ''}
        textColor={customersObj[retailer]?.footerConfig?.textColor || ''}
      >
        <Box>
          <Flex px={4} pb={66} flexDir={['column', 'column', 'column', 'row']}>
            <Stack
              direction={[
                'column',
                'column',
                footerStyles.stackDirection as 'column' | 'row',
              ]}
              spacing={16}
            >
              <VStack align='start' spacing={6}>
                <VStack align='start'>
                  {customersObj[retailer]?.footerConfig?.signForm &&
                    query.locale === 'en' && <SignForm />}
                  {currentLocale === 'es' && (
                    <Box
                      w={['auto', 'sm']}
                      css={{
                        h2: {
                          width: 200,
                          fontSize: 18,
                          fontWeight: '700',
                          marginBottom: 9,
                        },
                        p: {
                          fontSize: 12,
                          '.subtitle-two': {
                            fontSize: 18,
                            fontWeight: 700,
                          },
                        },
                      }}
                      dangerouslySetInnerHTML={{
                        __html: footerData?.checkBalance[currentLocale]?.text,
                      }}
                    />
                  )}
                  <Box
                    tabIndex={0}
                    display={
                      (currentLocale === 'en' &&
                        customersObj[retailer]?.footerConfig?.availableNone) ||
                      (currentLocale === 'es' &&
                        customersObj[retailer]?.footerConfig?.availableNoneEs)
                        ? 'none'
                        : 'block'
                    }
                  >
                    <Heading
                      as='h3'
                      fontSize={[20, 20, 20, 24]}
                      variant={'footerTitle'}
                    >
                      {t('available')}
                    </Heading>
                    <Text variant='footerSubText'>
                      {t('balance')}
                      <Text textDecor='underline'>
                        <NextLink
                          href={`/${query.locale}/topics?topicName=611611-services`}
                          passHref
                        >
                          {t('spanned_number')}
                        </NextLink>
                      </Text>
                    </Text>
                  </Box>
                </VStack>
                {customersObj[retailer]?.footerConfig?.support && (
                  <>
                    <FooterList
                      title={t('support')}
                      subItems={footerData.support}
                    />
                    <FooterList
                      title={t(`about-retailer.${retailer}`)}
                      subItems={footerData.aboutTracfone}
                    />
                  </>
                )}
              </VStack>
              {customersObj[retailer]?.connectWithUsSection && <VStack align='start' spacing={6}>
                <VStack align='start' spacing={4}>
                  <Heading
                    as='h3'
                    tabIndex={0}
                    fontSize={
                      customersObj[retailer]?.footerConfig?.textSmall
                        ? ''
                        : [20, 20, 20, 24]
                    }
                    variant={
                      customersObj[retailer]?.footerConfig?.textSmall
                        ? 'footerText_small'
                        : 'footerTitle'
                    }
                  >
                    {t('connect')}
                  </Heading>
                  <Flex alignItems='center' flexWrap='wrap' gap={8} ml={0}>
                    {iconData.map((el, i) => (
                      <NextLink passHref href={`${el.href}`} key={i}>
                        <Link
                          target='_blank'
                          cursor='pointer'
                          aria-label={el.linkAltText}
                          _hover={{ filter: 'contrast(0.5)' }}
                        >
                          {getIcons(el.icon)}
                        </Link>
                      </NextLink>
                    ))}
                  </Flex>
                </VStack>
                {customersObj[retailer].specialHeaderAndFooter && (
                  <Flex
                    justifyContent='space-between'
                    flexDirection={'row'}
                    columnGap={8}
                    flexDir={['column', 'column', 'column', 'row']}
                  >
                    <FooterList subItems={footerData.footerItems1} />
                    <FooterList subItems={editFooterItems2()} />
                  </Flex>
                )}
              </VStack>}
            </Stack>
            {customersObj[retailer]?.footerConfig?.shop && (
              <Stack
                minH={'3xl'}
                alignItems={'flex-start'}
                justify={'space-between'}
                ml={[0, 0, 0, 36]}
                mt={[15, 15, 16, 0]}
              >
                <VStack alignItems={'flex-start'}>
                  <Heading as='h3' variant='footerText_small' tabIndex={0}>
                    {t('shop')}
                  </Heading>
                  <Flex justifyContent='space-between' flexDirection={'row'}>
                    <FooterList subItems={footerData.shopLinks} />
                  </Flex>
                </VStack>
                <VStack alignItems={'flex-start'}>
                  <Heading as='h3' variant='footerText_small' tabIndex={0}>
                    {t('customer')}
                  </Heading>
                  <Flex justifyContent='space-between' flexDirection={'row'}>
                    <FooterList subItems={footerData.currentCustomers} />
                  </Flex>
                </VStack>
                <VStack mt={32}>
                  <FooterList
                    title={t(`about-retailer.${retailer}`)}
                    subItems={footerData.aboutTracfone}
                  />
                </VStack>
              </Stack>
            )}
          </Flex>
        </Box>
      </Flex>
      <Flex
        bgColor='footer'
        h={'auto'}
        w='full'
        flexDir={'column'}
        alignItems={'center'}
        justify='center'
        pr={paddingRight}
        pb={5}
      >
        {customersObj[retailer]?.footerConfig?.referConditionsLocal && (
          <Flex
            tabIndex={0}
            color={'text.footer'}
            fontSize={'xs'}
            mb={10}
            mt={10}
            px={[10, 10, 40, 52, 56]}
            w={['auto']}
          >
            {t(`refer_conditions.${retailer}`)}
          </Flex>
        )}
        {customersObj[retailer]?.footerConfig?.referConditions &&
        !customersObj[retailer]?.footerConfig?.referConditionsLocal ? (
          <Flex
            justify={['center']}
            align='center'
            flexDirection={['column']}
            px={[4, 4, 4, 10]}
          >
            <Flex>
              <Text
                fontSize='xs'
                pb={[6, 6, 6, 0]}
                px={[0, 0, 0, 48]}
                color='text.footer'
                tabIndex={0}
              >
                <Box
                  dangerouslySetInnerHTML={{
                    __html: footerData.footerInc.text,
                  }}
                />
              </Text>
            </Flex>
            {themes.logo && (
              <Image
                aria-label={`${customersObj[retailer].customerName} Logo`}
                unoptimized
                loader={exportableLoader}
                alt=''
                width={162}
                height={40}
                src={`/assets/${themes.logo}`}
                {...isMobile ? headerStyles?.logoStylesMobile : headerStyles?.logoStyles}
              />
            )}
            <Box />
          </Flex>
          ) : (
          <Flex
            justify={['center', 'center', 'center', 'space-between']}
            align='center'
            flexDirection={['column', 'column', 'column', 'row']}
            px={10}
          >
            <Flex>
              <Text maxW={'xs'} fontSize='xs' pb={[6, 6, 6, 0]} textColor='text.footer' 
                aria-label={retailersInfo.find((item) => item.retailer === retailer)
                  ?.titleFooter[currentLocale as 'en' | 'es']} tabIndex={0}>
                {retailersInfo.find((item) => item.retailer === retailer)
                  ?.titleFooter[currentLocale as 'en' | 'es']}
              </Text>
            </Flex>
            {themes.logo && (
              <Image
                tabIndex={0}
                aria-label={`${customersObj[retailer].customerName} Logo`}
                unoptimized
                loader={exportableLoader}
                alt=''
                width={162}
                height={40}
                src={`/assets/${themes.logo}`}
                {...isMobile ? headerStyles?.logoStylesMobile : headerStyles?.logoStyles}
              />
            )}
            <Box />
          </Flex>
          )}
        {!customersObj[retailer].specialHeaderAndFooter && (
              <Flex>
                <UnorderedList display='flex'>
                  {shortFooterItems.map((el, i) => (
                    <ListItem
                      _hover={{ 
                        opacity: '0.8',
                        textDecoration: 'underline',
                      }}
                      key={i}
                      listStyleType='none'
                      my={2}
                      mx={5}
                      fontSize={'xs'}
                      color='text.footer'
                      transition='opacity 0.2s ease-in-out'
                    >
                    <Link href={el?.linkUrl[currentLocale as 'en' | 'es']}>
                      <a
                        color='black'
                        target={'_blank'}
                        rel='noreferrer'
                      >
                        <Flex gap={1}>
                          {
                            el?.linkIcon && (
                              <Image
                                src={el?.linkIcon}
                                loader={exportableLoader}
                                width={30}
                                height={14}
                                alt={el?.linkText[currentLocale as 'en' | 'es']}
                              />
                            )
                          }
                          <Text
                            tabIndex={0}
                          >
                            {el?.linkText[currentLocale as 'en' | 'es']}
                          </Text>
                        </Flex>
                      </a>
                    </Link>
                  </ListItem>
                  ))}
                </UnorderedList>
              </Flex>
        )}
      </Flex>
    </>
  )
}

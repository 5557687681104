import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import { postAnalytics } from '../../analytics'
import { SearchGroupInput } from './SearchGroupInput'

export const SearchBox = ({
  refine,
  handleChangeInputSearch,
  inputSearch,
  selectedQuestion,
}: {
  currentRefinement: string
  refine: (val: string) => void
  handleChangeInputSearch: (val: string) => void
  inputSearch: string
  selectedQuestion: string
}) => {
  const { t } = useTranslation('common')

  const onHandleChangeInput = (value: string) => {
    handleChangeInputSearch(value)
    refine(value)
  }

  const onHandleKeyPress = (key: string) => {
    if (key !== 'Enter') return
    refine(inputSearch)
    postAnalytics({
      data: {
        event_category: 'search',
        search_term: inputSearch,
        page_url: location.href,
        action: 'submit',
      },
      category: 'search',
    })
  }

  useEffect(() => {
    refine(selectedQuestion)
  }, [selectedQuestion])

  return (
    <SearchGroupInput
      placeholder={t('searchPromptText')}
      iconWidth={12}
      height={12}
      font={'xl'}
      searchHandle={onHandleChangeInput}
      onKeyPress={({ key }) => onHandleKeyPress(key)}
      searchValue={inputSearch}
    />
  )
}

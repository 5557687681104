import { Heading, Flex, Text, Container, Button, Box } from '@chakra-ui/react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { ConfCtx } from '../../context/conf'
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter'
import {
  Configure,
  connectRefinementList,
  connectSearchBox,
  connectStats,
  Hits,
  Index,
  InstantSearch,
  Panel,
  SearchBox as DefaultSearchBox,
} from 'react-instantsearch-dom'
import { initTypesenseAdapter } from '../../lib/typesense-adapter'
import { client } from '../../services/kms.instance'
import { SelectedModel } from './SelectedModel'
import { SearchHitQuick } from './SearchHitQuick'
import { ProductInfo } from '../../services/kms'
import { SearchBox } from './SearchBox'
import { SearchFilterList } from './SearchFilterList'
import { SearchHit } from './SearchHit'
import { HitType } from '../../typesense.interface'
import { getTypesenseIndex } from '../../services'
import { RefinementListProvided } from 'react-instantsearch-core'
import { Stats } from './Stats'

interface TypeRefinementList extends RefinementListProvided {
  selectedItem: string[]
}

const RenderRefinementList = ({ refine, selectedItem }: TypeRefinementList) => {
  useEffect(() => {
    refine(selectedItem)
  }, [selectedItem])
  return <Box />
}

const CustomRefinementListTutorial = connectRefinementList(RenderRefinementList)
const CustomSearchBox = connectSearchBox(SearchBox)
const CustomStats = connectStats(Stats)
const CustomRefinementList = connectRefinementList(SearchFilterList)
const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter(
  initTypesenseAdapter({
    isFilteredGeneral: false,
  }),
)

const typesenseInstantsearchAdapterTutorials =
  new TypesenseInstantsearchAdapter(
    initTypesenseAdapter({
      isFilteredGeneral: true,
    }),
  )

export const Search = ({ defaultInput }: { defaultInput: string }) => {
  const { retailer, contentCustomerId } = useContext(ConfCtx)
  const indexNameTutorial = getTypesenseIndex('tutorials', contentCustomerId)
  const indexNameFaq = getTypesenseIndex('faqs', contentCustomerId)
  const {
    query: { locale },
  } = useRouter()
  const { t } = useTranslation('common')
  const defaultHitsPerPage = 6
  const gapPlusHits = 6

  const [selectedModel, setSelectedModel] = useState('')
  const [selectedVanityName, setSelectedVanityName] = useState('')
  const [selectedQuestion, setSelectedQuestion] = useState('')
  const [selectedManufacturer, setSelectedManufacturer] = useState('')
  const [inputSearch, setInputSearch] = useState(defaultInput)
  const [changeSelectedPhone, setChangeSelectedPhone] = useState(false)
  const [numberResultsTutorials, setNumberResultsTutorials] = useState(0)
  const [numberResultsFaqs, setNumberResultsFaqs] = useState(0)
  const [hitsPerPageFaqs, setHitsPerPageFaqs] = useState(defaultHitsPerPage)
  const [hitsPerPageTutorials, setHitsPerPageTutorials] = useState(defaultHitsPerPage)
  const [phoneInfo, setPhoneInfo] = useState<ProductInfo | null>(null)

  const isShowLessResultsBtnFaqs = useMemo(
    () => hitsPerPageFaqs > defaultHitsPerPage,
    [hitsPerPageFaqs],
  )
  const isShowMoreResultsBtnFaqs = useMemo(
    () => hitsPerPageFaqs < numberResultsFaqs,
    [hitsPerPageFaqs, numberResultsFaqs],
  )
  const isShowLessResultsBtnTutorials = useMemo(
    () => hitsPerPageTutorials > defaultHitsPerPage,
    [hitsPerPageTutorials],
  )
  const isShowMoreResultsBtnTutorials = useMemo(
    () => hitsPerPageTutorials < numberResultsTutorials,
    [hitsPerPageTutorials, numberResultsTutorials],
  )
  const isValidatedInputSearch = useMemo(() => {
    const regex = /[^A-Z0-9.,'/"?a-zñáéíóúü\s]/gi
    if (inputSearch.match(regex)) return false
    return true
  }, [inputSearch])

  const handleChangeInputSearch = (value: string) => {
    setInputSearch(value)
    setSelectedQuestion('')
  }

  const getPhoneInfo = async (phone: string) => {
    if (!changeSelectedPhone) return
    setChangeSelectedPhone(false)
    const responce = (await client.productInfo({
      referenceId: phone,
      customer: contentCustomerId,
      language: locale as string,
    })) as ProductInfo
    setPhoneInfo(responce)
  }

  return (
    <Container id='ContentToScrollTo' variant={'totalSupportContainer'}>
      <Heading as='h1' variant='totalSupport' tabIndex={0}>
        {t(`support.${retailer}`, { support: 'common' })}
      </Heading>
      <Container variant={'searchHomeContainer'}>
        <InstantSearch
          indexName={indexNameFaq}
          searchClient={typesenseInstantsearchAdapter.searchClient}
        >
          <CustomSearchBox
            handleChangeInputSearch={handleChangeInputSearch}
            inputSearch={inputSearch}
            selectedQuestion={selectedQuestion}
          />
          {!selectedQuestion && (
            <SearchHitQuick
              indexNameFaq={indexNameFaq}
              indexNameTutorial={indexNameTutorial}
              handleChangeInputSearch={handleChangeInputSearch}
              setSelectedQuestion={setSelectedQuestion}
              inputSearch={inputSearch}
              selectedManufacturer={selectedManufacturer}
              selectedModel={selectedModel}
            />
          )}
          {inputSearch && isValidatedInputSearch && (
            <Container variant={'searchHomeContentContainer'}>
              <Container variant={'searchHomeFilterContainer'}>
                <Panel>
                  <CustomRefinementList
                    header={t('manufacturer')}
                    showMore={false}
                    attribute={'manufacturers'}
                    searchable={false}
                    selectedItemChange={setSelectedManufacturer}
                    selectedItem={selectedManufacturer}
                  />
                </Panel>
                <Panel>
                  <CustomRefinementList
                    header={t('vName')}
                    showMore={true}
                    showMoreLimit={400}
                    attribute={'vanity_names'}
                    searchable={false}
                    selectedItemChange={setSelectedVanityName}
                    selectedItem={selectedVanityName}
                    setChangeSelectedPhone={setChangeSelectedPhone}
                    setPhoneInfo={setPhoneInfo}
                  />
                </Panel>
                <Panel>
                  <CustomRefinementList
                    header={t('model')}
                    showMore={true}
                    showMoreLimit={400}
                    attribute={'products'}
                    searchable={false}
                    selectedItemChange={setSelectedModel}
                    selectedItem={selectedModel}
                    setChangeSelectedPhone={setChangeSelectedPhone}
                    setPhoneInfo={setPhoneInfo}
                  />
                </Panel>
              </Container>
              <Container variant={'searchHomeHitContainer'}>
                {phoneInfo && <SelectedModel phoneInfo={phoneInfo} />}
                <Container variant={'searchGroupHits'}>
                  <InstantSearch
                    indexName={indexNameTutorial}
                    searchClient={
                      typesenseInstantsearchAdapterTutorials.searchClient
                    }
                  >
                    <Box display={'none'}>
                      <DefaultSearchBox defaultRefinement={inputSearch} />
                    </Box>
                    {selectedManufacturer && (
                      <CustomRefinementListTutorial
                        attribute='manufacturers'
                        selectedItem={[selectedManufacturer]}
                      />
                    )}
                    {selectedModel && (
                      <CustomRefinementListTutorial
                        attribute='products'
                        selectedItem={[selectedModel]}
                      />
                    )}
                    {selectedModel && (
                      <CustomRefinementListTutorial
                        attribute='vanity_names'
                        selectedItem={[selectedModel]}
                      />
                    )}
                    <Index indexName={indexNameTutorial}>
                      <Heading tabIndex={0} fontSize={24} mb={3}>{`${t(
                        'tutorials',
                      )}:`}</Heading>
                      <CustomStats
                        text={`${t('resultsFound')} ${t('tutorials')}`}
                        handleNumberResultsChange={
                          setNumberResultsTutorials
                        }
                      />
                      <Configure hitsPerPage={hitsPerPageTutorials} />
                      <Container variant={'searchHitsList'}>
                        <Hits
                          hitComponent={({ hit }: { hit: HitType }) =>
                            SearchHit({
                              getPhoneInfo,
                              hit,
                              locale,
                              selectedModel,
                              inputSearch,
                              selectedVanityName,
                            })
                          }
                        />
                      </Container>
                    </Index>
                    <Flex
                      gap={3}
                      mb={10}
                      alignItems={'center'}
                      flexDirection={'column'}
                    >
                      {isShowMoreResultsBtnTutorials && (
                        <Button
                          variant={'showMore'}
                          onClick={() => {
                            setHitsPerPageTutorials(hitsPerPageTutorials + gapPlusHits)
                          }}
                        >
                          {t('moreResults')}
                        </Button>
                      )}
                      {isShowLessResultsBtnTutorials && (
                        <Button
                          variant={'showMore'}
                          onClick={() => {
                            setHitsPerPageTutorials(hitsPerPageTutorials - gapPlusHits)
                          }}
                        >
                          {t('lessResults')}
                        </Button>
                      )}
                    </Flex>
                  </InstantSearch>
                  <Index indexName={indexNameFaq}>
                    <Heading tabIndex={0} fontSize={24} mb={3}>
                      FAQ's:
                    </Heading>
                    <CustomStats
                      text={`${t('resultsFound')} FAQ's`}
                      handleNumberResultsChange={setNumberResultsFaqs}
                    />
                    <Configure hitsPerPage={hitsPerPageFaqs} />
                    <Container variant={'searchHitsList'}>
                      <Hits
                        hitComponent={({ hit }: { hit: HitType }) =>
                          SearchHit({
                            getPhoneInfo,
                            hit,
                            inputSearch,
                            locale,
                            selectedModel,
                            selectedVanityName,
                          })
                        }
                      />
                    </Container>
                  </Index>
                  <Flex gap={3} alignItems={'center'} flexDirection={'column'}>
                    {isShowMoreResultsBtnFaqs && (
                      <Button
                        variant={'showMore'}
                        onClick={() => {
                          setHitsPerPageFaqs(hitsPerPageFaqs + gapPlusHits)
                        }}
                      >
                        {t('moreResults')}
                      </Button>
                    )}
                    {isShowLessResultsBtnFaqs && (
                      <Button
                        variant={'showMore'}
                        onClick={() => {
                          setHitsPerPageFaqs(hitsPerPageFaqs - gapPlusHits)
                        }}
                      >
                        {t('lessResults')}
                      </Button>
                    )}
                  </Flex>
                </Container>
              </Container>
            </Container>
          )}
          {!isValidatedInputSearch && (
            <Text color={'red'}>{t('forbiddenCharacters')}</Text>
          )}
        </InstantSearch>
      </Container>
    </Container>
  )
}

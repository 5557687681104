import { Text } from '@chakra-ui/react'
import { useEffect } from 'react'

type Props = {
  nbHits: number
  text: string
  handleNumberResultsChange: (val: number) => void
}

export const Stats = ({ nbHits, text, handleNumberResultsChange }: Props) => {
  useEffect(() => handleNumberResultsChange(nbHits), [nbHits])
  return (<Text tabIndex={0} fontSize={12} mb={2}>
    {`${nbHits} ${text}`}
  </Text>)
}

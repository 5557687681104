import {
  Box,
  Container,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Link,
  Heading,
  useTheme,
  Button,
} from '@chakra-ui/react'
import Image from 'next/image'
import NextLink from 'next/link'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Hamburger from '/public/assets/hamburger.png'
import { exportableLoader } from '../../image-loader'
import { useRouter } from 'next/router'
import nextI18nextConfig from '../../../next-i18next.config.js'
import LanguageSwitchLink from '../languageSwitchLink/languageSwitchLink.jsx'
import { useTranslation } from 'next-i18next'
import { getHeaderItems } from '../../services'
import { HeaderData, Item } from '../../content/headerData.interface'
import { ShopAccordion } from '../shopList/shopList'
import { HeaderAccordion } from '../headerAccordion/headerAccordion'
import { MobileHeader } from '../mobileHeader/mobileHeader'
import {
  ShoppingCartIcon,
  UserIcon,
} from '../../styles/components/customIcons'
import { ConfCtx } from '../../context/conf'
import { themes as theme } from '../../styles/themes'
import { customersObj } from '../../../customer-config'
import LocaleSolid from '/public/assets/locale_solid.svg'
import AccountSolid from '/public/assets/account_solid.svg'
import ShopSolid from '/public/assets/shop_solid.svg'
import LogoStraighttalk from '/public/assets/straighttalkLogo_header.png'
import { ShopListStraighttalk } from '../shopList/ShopListStraighttalk'
import { MobileHeaderSliding } from '../mobileHeader/MobileHeaderSliding'
import { HeaderNavLinks } from './headerNavLinks'

export type HeaderList = {
  links: HeaderData['links']
  shopCartLink: string
  item: Item[]
  defaultItems: { linkUrl: string; linkAltText: string; linkText: string }[]
  navigationLinks:
  | { linkUrl: string; linkAltText: string; linkText: string }[]
  | []
}

const getIcons = (type: string) => {
  switch (type) {
    case 'ShoppingCartIcon':
      return <ShoppingCartIcon />
    case 'UserIcon':
      return <UserIcon />
    default:
      break
  }
}

const ShopList = ({
  retailer,
  ...props
}: {
  retailer: string
  headerData: Item[]
  index: number
  isMobile: boolean
}) => {
  switch (retailer) {
    case 'straighttalk':
      return (
        <ShopListStraighttalk
          headerData={props.headerData}
          isMobile={props.isMobile}
          index={props.index}
        />
      )
    default:
      return <ShopAccordion headerData={props.headerData} isMobile={true} />
  }
}

export const Header = () => {
  const { query } = useRouter()
  const { retailer } = useContext(ConfCtx)
  const themes: any = { ...theme(retailer), ...useTheme() }
  const { headerStyles } = themes
  const styleConfig = customersObj[retailer]?.headerConfig

  const { t } = useTranslation('header')

  const currentLocale: any =
    query.locale || nextI18nextConfig.i18n.defaultLocale

  const [screenWidth, setScreenWidthsetValue] = useState(
    typeof window !== 'undefined' && window.screen.width,
  )
  const [collapsedAccordion, setCollapsedAccordion] = useState({
    index: 0,
    isCollapsed: true,
  })
  const [headerList, setHeaderList] = useState<HeaderList>({
    defaultItems: [
      {
        linkUrl: `/${query.locale}/brands/`,
        linkAltText: t('phoneHelp'),
        linkText: t('phoneHelp'),
      },
      {
        linkUrl: `/${query.locale}/topics`,
        linkAltText: t('FAQ'),
        linkText: t('FAQ'),
      },
    ],
    links: [],
    shopCartLink: '',
    navigationLinks: [],
    item: [],
  })
  const headerRef = useRef<any>()
  const headerListRef = useRef<any>()
  const navRef = useRef<any>()
  const panelRef = useRef<any>()
  const logoRef = useRef<any>()

  const isEng = currentLocale === 'en'
  const currentLang = isEng ? 'en' : 'es'

  const getScreenWidth = (event: any) =>
    setScreenWidthsetValue(event.target.innerWidth)

  const retrieveHeaderData = async () => {
    const list = await getHeaderItems(
      currentLocale,
      customersObj[retailer].headerFooterUrl,
    )
    setHeaderList({
      ...headerList,
      links: list.links,
      shopCartLink:
        list.items.navigation.cartIconUrl ||
        `${customersObj[retailer].headerExternalLinkBase}${list.cartIconUrl}`,
      item: list.items.navigation.item ?? list.items.navigation.items,
      navigationLinks: list.items.navigation.navigationLinks,
    })
  }

  const onHandleToggleAccordion = (index: number) => {
    if (index === collapsedAccordion.index) {
      setCollapsedAccordion(() => ({
        index,
        isCollapsed: !collapsedAccordion.isCollapsed,
      }))
    }

    if (index === -1) {
      setCollapsedAccordion(() => ({ index, isCollapsed: true }))
    }

    if (index !== collapsedAccordion.index) {
      setCollapsedAccordion(() => ({ index, isCollapsed: false }))
    }
  }

  const onBodyOverflow = (value: string) => {
    const bodyEl = document.querySelector('body')
    if (bodyEl) {
      bodyEl.style.overflow = value
    }
  }

  const isMobile = typeof window !== 'undefined' && screenWidth <= 991.98

  const hrefAnchor = (e: {
    key: string
    currentTarget: { blur: () => void }
  }) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur()
      const ContentToScrollTo = document.querySelector('#ContentToScrollTo')
      ContentToScrollTo?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  useEffect(() => {
    retrieveHeaderData()
  }, [currentLocale, isEng, retailer])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', getScreenWidth)
      return () => {
        window.removeEventListener('resize', getScreenWidth)
      }
    }
  }, [])

  return (
    <Container
      ref={headerRef}
      top='0'
      zIndex='9'
      variant={styleConfig?.headerFlex ? 'header_flex' : 'header'}
      h={customersObj[retailer].specialHeaderAndFooter ? '' : [14, 14, 14, 20]}
      transition='0.3s all ease'
      display={'flex'}
      justifyContent={'center'}
    >
      <Button
        onKeyDown={hrefAnchor}
        position='absolute'
        top='0'
        left='0'
        transform='translateX(-100%)'
        transition='all 0.3s ease-in-out'
        zIndex='-999'
        color='white'
        bgColor='black'
        opacity='0'
        textAlign='center'
        textTransform='uppercase'
        fontSize={[8, 10]}
        padding={['7.5px 10px', '10px 15px']}
        aria-label='Skip to main content'
        _focus={{
          borderRadius: '0 5px 5px 0',
          fontWeight: 'bold',
          zIndex: '999',
          opacity: '1',
          transform: 'translateX(0)',
          boxShadow: '0 5px 10px 0 rgb(0 0 0 / 30%)',
        }}
        _hover={{
          opacity: '0.8',
        }}
      >
        Skip to main content
      </Button>
      <Flex 
        maxW={styleConfig?.separatedLogo
          ? ['100%', '100%', '100%', '994px', '1240px']
          : ['100%', '100%', '100%', '100%', '1240px']
        }
        margin='0 auto'
        w={'100%'}
        px={'10px'}
      >
        {styleConfig?.separatedLogo && (
          <Box
            display={['none', 'none', 'none', 'block']}
            ref={logoRef}
            position='relative'
            bottom={0}
            pl={1}
            pb={2}
          >
            <NextLink
              href={`/${currentLocale}`}
              passHref
            >
              <Link
                aria-label={`${customersObj[retailer].customerName} ${t('ariaLabelLogo')}`}
                display='block'
                tabIndex={0}
                cursor='pointer'
                variant='navLink'
                width={[10, 10, 10, 24, 28]}
              >
                <Image
                  {...headerStyles?.separatedLogo}
                  unoptimized
                  loader={exportableLoader}
                  alt={''}
                  width={111}
                  height={83}
                />
              </Link>
            </NextLink>
          </Box>
        )}
        <Flex flexDir={'column'} w='100%' alignItems={'center'}>
          {customersObj[retailer].specialHeaderAndFooter && (
            <Box bgColor={'headerHelmet.bg'} w='100%'>
              <Flex
                justify='flex-end'
                ref={headerListRef}
                h={8}
                alignItems='center'
                color='text.header'
                gap={5}
                display={['none', 'none', 'none', 'flex']}
                {...headerStyles?.helmet?.container}
                px={['2%', '2%', '4%', styleConfig?.mobile ? 1 : 5, 1]}
                pl={
                  styleConfig?.separatedLogo
                    ? ['3%', '3%', '3%', '2.5%', 7]
                    : 0
                }
              >
                <Flex alignItems={'center'} justify={'flex-start'} gap={4}>
                  <Flex align={'center'}>
                    {styleConfig?.languageIcon && (
                      <Image
                        unoptimized
                        loader={exportableLoader}
                        alt='locale'
                        src={LocaleSolid}
                      />
                    )}
                    <Text
                      variant='headerList'
                      fontSize={'xs'}
                      {...headerStyles?.helmet?.languageStyle}
                    >
                      {nextI18nextConfig.i18n.locales.map((locale) => {
                        if (locale === currentLocale) return null
                        return (
                          <LanguageSwitchLink locale={locale} key={locale} />
                        )
                      })}
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  gap={4}
                  align={'center'}
                  pr={
                    styleConfig?.separatedLogo
                      ? [0, 0, 0, 0, 3]
                      : 0
                  }
                >
                  {headerList.navigationLinks && styleConfig?.rewardsUp && (
                    <Text
                      variant={
                        styleConfig?.boldLink ? 'headerList_bold' : 'headerList'
                      }
                      key={'rewards'}
                    >
                      <a
                        aria-label={'rewards'}
                        href={headerList.navigationLinks[1]?.linkUrl}
                      >
                        {headerList.navigationLinks[1]?.linkText}
                      </a>
                    </Text>
                  )}
                  <Text
                    variant={
                      styleConfig?.boldLink ? 'headerList_bold' : 'headerList'
                    }
                    display={styleConfig?.notGoToRetailer ? 'none' : ''}
                  >
                    <a
                      href={`${customersObj[retailer].headerExternalLinkBase}/${
                        !isEng ? currentLocale : ''
                      }`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {t(`goTo.${retailer}`)}
                    </a>
                  </Text>
                  {headerList.links?.map((el, i) => {
                    if (el.linkText !== 'Find a store')
                      return (
                        <Text
                          variant={
                            styleConfig?.boldLink
                              ? 'headerList_bold'
                              : 'headerList'
                          }
                          key={i}
                        >
                          {/* TODO: fix this quick ugly solution */}
                          <a
                            aria-label={el.linkAltText}
                            href={`${
                              customersObj[retailer].headerExternalLinkBase
                            }/${
                              el.linkUrl[0] === '/'
                                ? el.linkUrl.slice(1)
                                : el.linkUrl
                            }`}
                            rel={el.doNotFollowLink}
                          >
                            {el.linkText}
                          </a>
                        </Text>
                      )
                  })}
                  {styleConfig?.helmetIcon && (
                    <>
                      <Flex cursor={'pointer'} alignItems={'center'}></Flex>
                      <Link
                        display={'flex'}
                        target={'_blank'}
                        variant='navLink'
                        href={customersObj[retailer].loginLink[currentLang]}
                      >
                        <Image
                          unoptimized
                          loader={exportableLoader}
                          alt=''
                          src={AccountSolid}
                        />
                      </Link>
                      <Link
                        display={'flex'}
                        target={'_blank'}
                        variant='navLink'
                        pr='7px'
                        aria-label={t('shopCart')}
                        href={headerList.shopCartLink}
                      >
                        <Image
                          unoptimized
                          loader={exportableLoader}
                          alt=''
                          src={ShopSolid}
                        />
                      </Link>
                    </>
                  )}
                </Flex>
              </Flex>
            </Box>
          )}
          <Flex
            px={['2%', '2%', '2%', 5, 0]}
            ref={navRef}
            w={'100%'}
            maxW={1800}
            h={['100%', '100%', '100%']}
            justify={'flex-start'}
            color='text.header'
            bgColor={'header'}
            align='center'
            minH={12}
            {...headerStyles?.accordionContainer}
          >
            <Flex
              display={['block', 'block', 'block', 'none']}
              align='center'
            >
              <Flex justify={'flex-start'}>
                <Menu
                  onOpen={() => onBodyOverflow('hidden')}
                  onClose={() => onBodyOverflow('auto')}
                  isOpen={!collapsedAccordion.isCollapsed}
                >
                  <MenuButton
                    aria-label={'menu'}
                    ref={panelRef}
                    onClick={() =>
                      setCollapsedAccordion((prev) => ({
                        index: 0,
                        isCollapsed: !prev.isCollapsed,
                      }))
                    }
                  >
                    <Image
                      style={ { ...headerStyles?.menuIcon } }
                      unoptimized
                      loader={exportableLoader}
                      alt=''
                      src={Hamburger}
                    />
                  </MenuButton>
                  <MenuList
                    display={'flex'}
                    flexDirection={'column'}
                    width={'100vw'}
                    height={'100vh'}
                    border={'none'}
                    bgColor={'white'}
                    overflowY={'auto'}
                    borderRadius={'none'}
                    zIndex={9}
                    borderTop={'1px solid'}
                    borderTopColor={'gray.200'}
                    {...headerStyles?.menuList}
                    p={0}
                  >
                    {styleConfig?.mobileHeaderSliding ? (
                      <MobileHeaderSliding
                        headerList={headerList}
                        currentLocale={currentLocale}
                        isExpandedMenu={collapsedAccordion.isCollapsed}
                      />
                    ) : (
                      <MobileHeader
                        headerList={headerList}
                        currentLocale={currentLocale}
                        isEng={isEng}
                      />
                    )}
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
            {styleConfig?.separatedLogo && (
              <Box
                display={['flex', 'flex', 'flex', 'none']}
                justifyContent='center'
                ref={logoRef}
                pos={'relative'}
                bottom={0}
                width={'100%'}
              >
                <NextLink
                  href={`/${currentLocale}`}
                  passHref
                >
                  <Link
                    aria-label={`${customersObj[retailer].customerName} ${t('ariaLabelLogo')}`}
                    display='block'
                    tabIndex={0}
                    cursor='pointer'
                    variant='navLink'
                    width={[14, 14, 14, 'auto', 180]}
                  >
                    <Image
                      unoptimized
                      loader={exportableLoader}
                      src={LogoStraighttalk}
                      alt=''
                      width={111}
                      height={83}
                    />
                  </Link>
                </NextLink>
              </Box>
            )}
            {!styleConfig?.separatedLogo && themes.logo && (
              <Box 
                ref={logoRef} 
                pos={'relative'} 
                bottom={0}
                display={['flex', 'flex', 'flex', 'block']}
                w={['100%', '100%', '100%', 'auto']} 
                justifyContent={['center']}
                
                >
                <NextLink
                  href={`/${currentLocale}`}
                  passHref
                >
                  <Link
                    aria-label={`${customersObj[retailer].customerName} ${t('ariaLabelLogo')}`}
                    display='block'
                    tabIndex={0}
                    cursor='pointer'
                    width={[125, 125, 125, 'auto', 180]}
                    borderRadius={5}
                    padding={1}
                    mr={1}
                    variant='navLink'
                  >
                    <Image
                      unoptimized
                      loader={exportableLoader}
                      src={`/assets/${themes.logo}`}
                      alt={''}
                      width={162}
                      height={42}
                      {...isMobile ? headerStyles?.logoStylesMobile : headerStyles?.logoStyles}
                      {...headerStyles?.logoWhite}
                    />
                  </Link>
                </NextLink>
              </Box>
            )}
            <Container
              variant='mainHeaderBlock'
              width='100%'
              {...headerStyles?.mainHeaderBlock}
            >
              {!customersObj[retailer].specialHeaderAndFooter ? (
                <Flex justify='center' width='full' gap={5}>
                  {headerList.defaultItems?.map((item, index) => (
                    <NextLink href={item.linkUrl} passHref key={index}>
                      <Link aria-label={item.linkAltText} variant='navLink'>
                        {item.linkText}
                      </Link>
                    </NextLink>
                  ))}
                </Flex>
              ) : (
                <Flex
                  alignItems={'center'}
                  justify={'flex-start'}
                  width={'100%'}
                  position={'relative'}
                  {...headerStyles?.headerAccordion?.buttonContainer}
                >
                  {styleConfig?.specialShopList
                    ? headerList.item.map((item, i) =>
                      item.children.length ? (
                        <HeaderAccordion
                          onHandleToggleAccordion={onHandleToggleAccordion}
                          collapsedAccordion={collapsedAccordion}
                          panelRef={panelRef}
                          display={''}
                          title={item.title}
                          index={i}
                          key={i}
                        >
                          <ShopList
                            retailer={retailer}
                            headerData={headerList.item}
                            index={i}
                            isMobile={isMobile}
                          />
                        </HeaderAccordion>
                      ) : (
                          <Text
                            px={3}
                            key={i}
                            textAlign='center'
                            variant={'navText'}
                          >
                            <Link
                              tabIndex={0}
                              _hover={{
                                background: 'none',
                                textDecoration: 'underline',
                              }}
                              aria-label={item.title}
                              href={`${customersObj[retailer].headerExternalLinkBase}${item.url}`}
                              fontSize={['xs', 'xs', 'xs', 'xs', 'sm', 'sm']}
                              px={3}
                              py={2}
                              variant='navLink'
                            >
                              {item.title.toUpperCase()}
                            </Link>
                          </Text>
                      ),
                    )
                    : headerList.item.map((item, i) =>
                      item.children.length ? (
                        <HeaderAccordion
                          onHandleToggleAccordion={onHandleToggleAccordion}
                          collapsedAccordion={collapsedAccordion}
                          panelRef={panelRef}
                          display={''}
                          title={item.title}
                          index={i}
                          key={i}
                        >
                          {i === 0 ? (
                            <ShopAccordion
                              headerData={headerList.item}
                              isMobile={isMobile}
                            />
                          ) : (
                            <Flex
                              alignItems={'center'}
                              flexDirection={'column'}
                              {...headerStyles?.headerAccordion?.flex}
                            >
                              <Heading
                                as={'h2'}
                                variant={'refillHeading'}
                                tabIndex={0}
                                {...headerStyles?.headerAccordion?.heading}
                              >
                                {item.flyoutHeader}
                                {item.flyoutButtonLabel && (
                                  <Link
                                  tabIndex={-1}
                                  href={item.url}
                                >
                                  <Button
                                    tabIndex={0}
                                    variant={'headerAccordionFlyout'}
                                  >
                                    {item.flyoutButtonLabel.toUpperCase()}
                                  </Button>
                                </Link>
                                )}
                              </Heading>
                              <Flex
                                flexDirection={'column'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                gap={3}
                                flexWrap={'nowrap'}
                                {...headerStyles?.headerAccordion
                                  ?.flexChildren}
                              >
                                {styleConfig?.shopListLogo
                                  ? item.children.map((child, index) => (
                                      <Flex
                                        key={index}
                                        flexDir={'column'}
                                        w={48}
                                        columnGap={6}
                                        mb={10}
                                      >
                                        <Flex
                                          p={4}
                                          border={'1px solid'}
                                          w={'100%'}
                                          justify={'center'}
                                          alignItems={'center'}
                                          borderColor={'gray.200'}
                                        >
                                          {child.s7thumbnailurl && (
                                            <Image
                                              unoptimized
                                              loader={exportableLoader}
                                              src={child.s7thumbnailurl}
                                              alt={`tracfone_${index}`}
                                              width={42}
                                              height={42}
                                            />
                                          )}
                                        </Flex>
                                        <Link
                                          mt={4}
                                          key={index}
                                          href={child.url}
                                          fontWeight={'semibold'}
                                          color={'text.header'}
                                          fontSize={'lg'}
                                          textAlign={'center'}
                                          maxW={40}
                                          tabIndex={0}
                                          variant='navLink'
                                        >
                                          {child.title}
                                        </Link>
                                      </Flex>
                                  ))
                                  : item.children.map((child, index) => (
                                      <Link
                                        key={index}
                                        href={child.url}
                                        fontSize={'sm'}
                                        tabIndex={0}
                                        variant='navLink'
                                      >
                                        {child.title}
                                      </Link>
                                  ))}
                              </Flex>
                            </Flex>
                          )}
                        </HeaderAccordion>
                      ) : (
                          <Text
                            px={3}
                            key={i}
                            textAlign='center'
                            variant={'navText'}
                            {...headerStyles?.shopList?.navText}
                          >
                            <Link
                              _hover={{
                                background: 'none',
                                textDecoration: 'underline',
                              }}
                              fontSize={['sm', 'sm', 'sm', 'sm', 'md', 'md']}
                              aria-label={item.title}
                              href={item.url}
                              tabIndex={0}
                              py={1}
                              px={3}
                              variant='navLink'
                              {...headerStyles?.headerAccordion
                                ?.accordionButton}
                            >
                              {item.title}
                            </Link>
                          </Text>
                      ),
                    )}
                </Flex>
              )}
              {customersObj[retailer].specialHeaderAndFooter &&
                !styleConfig?.rewardsUp && (
                  <Container variant={'headerBlock'}>
                    <HeaderNavLinks
                      headerList={headerList.navigationLinks}
                      isEng={isEng}
                    />
                  </Container>
              )}
            </Container>
            {customersObj[retailer].specialHeaderAndFooter ? (
              <Flex
                pos={['absolute', 'absolute', 'absolute', 'initial']}
                right={[2, 2, 5, 5]}
                alignItems={'center'}
                gap={5}
              >
                {isMobile ? (
                  styleConfig?.solidLocationIcon ? (
                    <Flex {...headerStyles?.iconFilter}>
                      <Link
                        aria-label={t('account')}
                        display={'flex'}
                        target={'_blank'}
                        variant='navLink'
                        mr={3}
                        href={customersObj[retailer].loginLink[currentLang]}
                    >
                        <Image
                          unoptimized
                          loader={exportableLoader}
                          alt=''
                          src={AccountSolid}
                        />
                      </Link>
                      <Link
                        mr={2}
                        display={'flex'}
                        target={'_blank'}
                        variant='navLink'
                        aria-label={t('shopCart')}
                        href={headerList.shopCartLink}
                      >
                        <Image
                          unoptimized
                          loader={exportableLoader}
                          alt=''
                          src={ShopSolid}
                        />
                      </Link>
                    </Flex>
                  ) : (
                    <Link aria-label={t('account')} href={customersObj[retailer].loginLink[currentLang]}>
                      {getIcons('UserIcon')}
                    </Link>
                  )
                ) : (
                  !styleConfig?.shopIconUp && (
                    <Link
                      display={'flex'}
                      target={'_blank'}
                      variant='navLink'
                      aria-label={t('shopCart')}
                      href={headerList.shopCartLink}
                      tabIndex={0}
                    >
                      {styleConfig?.solidLocationIcon ? (
                        <Image
                          height={35}
                          width={35}
                          unoptimized
                          loader={exportableLoader}
                          alt=''
                          src={ShopSolid}
                          style={ { ...headerStyles?.iconFilter } }
                        />
                      ) : (
                        getIcons('ShoppingCartIcon')
                      )}
                    </Link>
                  )
                )}
              </Flex>
            ) : (
              !isMobile && (
                <Text
                  variant='headerList'
                  color={'auto'}
                  fontSize={'xs'}
                  {...headerStyles?.helmet?.languageStyle}
                >
                  {nextI18nextConfig.i18n.locales.map((locale) => {
                    if (locale === currentLocale) return null
                    return <LanguageSwitchLink locale={locale} key={locale} />
                  })}
                </Text>
              )
            )}
          </Flex>
        </Flex>
      </Flex>
    </Container>
  )
}


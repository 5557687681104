import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { getSpecificationsIcons, iconsArray } from '../../content/iconsArray'
import { ProductInfo } from '../../services/kms'

type Props = {
  phoneInfo: ProductInfo
}

export const SelectedModel = ({ phoneInfo }: Props) => {
  const IMAGE_URL_BASE = process.env.imageUrlBase
  const { t } = useTranslation('common')

  const findSpecImage = (specName: string) => {
    const spec = iconsArray.find((item) =>
      item.variants
        .map((i) => i.toLowerCase())
        .includes(specName.toLowerCase().trim(), 0),
    )
    return spec?.src
  }

  const mainImage = useMemo(
    () =>
      phoneInfo ? `${IMAGE_URL_BASE}${phoneInfo.documents?.main_image}` : '',
    [phoneInfo, IMAGE_URL_BASE],
  )

  return (
    <Container variant={'searchSelectedModel'} display={phoneInfo ? 'flex' : 'none'}>
      <Box
        mr={5}
        w={[36, 36, 32, 32, 24]}
        position='relative'>
        <picture>
          <source srcSet={mainImage} />
          <img src={mainImage} alt='phone' />
        </picture>
      </Box>
      <Flex
        w={['auto', 'auto', 'full']}
        flexDir={'column'}
        justifyContent={'space-between'}
        alignItems={['center', 'stretch', 'stretch']}>
        <Heading fontSize={24} as='h1' tabIndex={0}>
          {phoneInfo.vanity_name}
        </Heading>
        <Text fontWeight={'bold'}>{phoneInfo.product.name}</Text>
        {phoneInfo.specs.length > 0 && (
          <Text cursor={'default'} variant='showAll'>
            {t('technical_specifications')}
          </Text>
        )}
        <Grid
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(3, 1fr)',
          ]}
          gap={2}
          overflowX='auto'
        >
          {phoneInfo.specs.map((el, i: number) => (
            <GridItem key={i} placeItems='center'>
              <Flex gap={4}>
                {getSpecificationsIcons(String(findSpecImage(el.name)))}
                <Box>
                  <Heading variant='phoneFeatureType' as='h4' tabIndex={0}>
                    {el.name}
                  </Heading>
                  <Text variant='phoneFeatures' tabIndex={0}>{el.value}</Text>
                </Box>
              </Flex>
            </GridItem>
          ))}
        </Grid>
      </Flex>
    </Container>
  )
}

import { 
  Container,
  Link,
} from '@chakra-ui/react'
import React, { useContext } from 'react'
import { ConfCtx } from '../../context/conf'
import { customersObj } from '../../../customer-config'

type HeaderListType = {
  linkAltText: string,
  linkText: string,
  linkUrl: string,
}[]

export const HeaderNavLinks = ({
  headerList,
  isEng,
}: {
  headerList: HeaderListType
  isEng: boolean,
}) => {
  const { retailer } = useContext(ConfCtx)

  const currentLang = isEng ? 'en' : 'es'

  const dynamicNavLinks = (index: number) => {
    if (index === 0) {
      return customersObj[retailer].loginLink[currentLang]
    } else {
      return headerList[index]?.linkUrl
    }
  }

  return (
    <Container variant='headerBlock'>
       {headerList?.map((item, index) =>
          <Link
            href={dynamicNavLinks(index)}
            target='_blank'
            aria-label={item.linkAltText}
            key={index}
            fontSize={['sm', 'sm', 'sm', 'sm', 'md']}
            variant='navLink'
          >
            {item.linkText}
          </Link>,
       )}
    </Container>
  )
}
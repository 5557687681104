import React, { useContext } from 'react'
import Image from 'next/image'
import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Link,
  ListItem,
  UnorderedList,
  useTheme,
  Button,
} from '@chakra-ui/react'
import { ctaImage } from '../../content/ctaImage'
import { Item } from '../../content/headerData.interface'
import { exportableLoader } from '../../image-loader'
import { theme } from '../../styles/theme'
import { customersObj } from '../../../customer-config'
import { ConfCtx } from '../../context/conf'

export const ShopAccordion = ({
  headerData,
  isMobile,
}: {
  headerData: Item[]
  isMobile: boolean
}) => {
  const { retailer } = useContext(ConfCtx)
  const themes: any = { ...theme(retailer), ...useTheme() }
  const { headerStyles } = themes
  const styleConfig = customersObj[retailer]?.headerConfig

  return (
    <Box>
      <Flex
        p={[3, 3, 4]}
        gap={3}
        justifyContent={'space-between'}
        flexDir={['column-reverse', 'column-reverse', 'column', 'row']}
        {...headerStyles?.shopList?.mainContainer}
      >
        <Flex
          flexDir={['column', 'column', 'row']}
          gap={[0, 0, 6]}
          pt={[6, 6, 0]}
        >
          {headerData &&
            headerData[0].children.map((item, index) => (
              <Container key={index} variant='shopList'>
                <Heading
                  tabIndex={0}
                  variant={'shopList'}
                  {...headerStyles?.shopList?.heading}
                >
                  {item.title}
                </Heading>
                <Container px={0} mx={0} mt={0}>
                  <UnorderedList listStyleType={'none'} ms={'0'}>
                    {item.children.map((child, i) => (
                      <ListItem
                        aria-label={child.title}
                        key={i}
                        pb={1.5}
                        fontSize={['sm', 'sm', 'xs']}
                        {...headerStyles?.shopList?.unorderedList?.listItem}
                      >
                        <Link 
                          tabIndex={-1}
                          href={child.url}
                        >
                          <Button
                            tabIndex={0}
                            p={0}
                            whiteSpace='normal'
                            height='auto'
                            width='auto'
                            minWidth='auto'
                            bg='transparent'
                            textAlign='left'
                            fontWeight='inherit'
                            fontSize='inherit'
                            variant='focusedButton'
                            _focus={{
                              boxShadow: 'none',
                            }}
                          >
                            {child.title}
                          </Button>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Container>
              </Container>
            ))}
        </Flex>
        <Flex
          key={1}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          flexDirection={'column'}
          pos='relative'
          {...headerStyles?.shopList?.downContainer}
        >
          {document.documentElement.offsetWidth > 768 && (
            <Divider
              pos='absolute'
              left={'-3'}
              orientation={'vertical'}
              height={255}
              {...headerStyles?.shopList?.divider}
            />
          )}
          <Heading
            tabIndex={0}
            fontSize={'lg'}
            as='h2'
            display={
              isMobile && styleConfig?.mobile?.withoutHeading ? 'none' : ''
            }
            {...headerStyles?.shopList?.downHeading}
          >
            {headerData[0].quickLinksHeading}
          </Heading>
          {headerData[0]?.quickLinks?.map((cta, index) => (
            <Link
              href={cta.ctaPath}
              key={index}
              _hover={{ color: 'gray.500' }}
              width={'full'}
              h={'auto'}
              mt={2}
              tabIndex={0}
              {...headerStyles?.shopList?.link}
              variant='navLink'
            >
              <Container
                variant={'shopListContainer'}
                {...headerStyles?.shopList?.shopListContainer}
                mt={0}
              >
                {(!isMobile || !styleConfig?.mobile?.withoutIconShop) && (
                  <Image
                    width={55}
                    height={55}
                    src={ctaImage[index].src}
                    unoptimized
                    loader={exportableLoader}
                    alt={ctaImage[index].iconAltText}
                  />
                )}
                <Container
                  dangerouslySetInnerHTML={{
                    __html: cta.ctaText,
                  }}
                  variant={'navText'}
                  {...headerStyles?.shopList?.navText}
                />
              </Container>
            </Link>
          ))}
        </Flex>
      </Flex>
    </Box>
  )
}

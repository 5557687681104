import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  Text,
  Heading,
  Link,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { postAnalytics } from '../../analytics'
import { Faq } from '../../services/kms'

export const FaqList = ({ faqs }: { faqs: Faq[] }) => {
  const { t } = useTranslation('common')
  const { asPath, query } = useRouter()
  const [path, restQuery = ''] = asPath.split('?')

  return (
    <Box my={10} mt={10}>
      <Flex justify='center' px={4}>
        <Container variant='faqBox'>
          <Heading variant='faqHeading' tabIndex={0}>{t('commonQuestions')}</Heading>
          <Accordion allowToggle>
            {faqs?.map((el, i) => (
              <AccordionItem key={i} borderColor={'gray.100'} display={el.title ? 'block' : 'none'}>
                <AccordionButton _focus={{ bg: 'blackAlpha.100', boxShadow: 'none' }}>
                  <Text variant='topic' fontSize={[14, 14, 16]} key={el.serial}>
                    {el.title}
                  </Text>
                </AccordionButton>
                <AccordionPanel>
                  <Box ml={4}>
                    <Container
                      tabIndex={0}
                      variant='answers'
                      dangerouslySetInnerHTML={{
                        __html: el.answer,
                      }}
                    ></Container>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ))}
            <Flex justify='center'>
              <Link
                _hover={{ textDecoration: 'none' }}
                variant={'showAll'}
                aria-label={`${t('showAll')} FAQs`}
                href={path + 'topics' + '/?' + restQuery}
                onClick={() =>
                  postAnalytics({
                    data: {
                      source: 'Related-swimlane',
                      event_category: 'navigation',
                      link_to: `${query.locale}/topics`,
                    },
                    category: 'navigation',
                  })
                }
              >
                {t('showAll')}
              </Link>
            </Flex>
          </Accordion>
        </Container>
      </Flex>
    </Box>
  )
}

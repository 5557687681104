import {
  Button,
  CloseButton,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import React, { KeyboardEventHandler } from 'react'
import Search from '../../../public/assets/search-normal.svg'
import { exportableLoader } from '../../image-loader'

type SearchGroupParams = {
  iconWidth: string | number
  height: string | number
  placeholder?: string
  containerWidth?: number | (number | string)[]
  font: string | number
  searchHandle: (e: string) => void
  onKeyPress?: KeyboardEventHandler<HTMLInputElement> | undefined
  onSearchIconClick?: () => void
  searchValue?: string | undefined
}

export const SearchGroupInput = ({
  iconWidth,
  height,
  placeholder,
  containerWidth,
  font,
  searchHandle,
  onKeyPress,
  onSearchIconClick,
  searchValue,
}: SearchGroupParams) => {
  const { t } = useTranslation('common')
  return (
    <Flex justify='center' w='full'>
      <InputGroup
        pr={0}
        borderColor='gray.200'
        w={containerWidth ?? [333, 333, 450, 552]}
      >
        <Input
          tabIndex={0}
          rounded={0}
          h={height}
          fontSize={[14, 16, font]}
          placeholder={placeholder}
          value={searchValue}
          onChange={({ target: { value } }: { target: { value: string } }) => {
            searchHandle(value)
          }}
          onKeyPress={onKeyPress}
        />
        <InputRightElement
          as='div'
          h={height}
          w={Number(iconWidth) + Number(iconWidth)}
        >
          <Flex
            justify='center'
            align='center'
            h={Number(height) - 2}
            w={iconWidth}
            bg='mainWhite'
          >
            <CloseButton
              aria-label={t('clearSearchField')}
              size='sm'
              onClick={() => {
                searchHandle('')
              }}
              _focus={{ boxShadow: '0 0 0 0.5px lightgrey' }}
            />
          </Flex>
          <Button
            h={height}
            w={iconWidth}
            aria-label={t('search')}
            variant='inputSearchButton'
            onClick={onSearchIconClick}
            tabIndex={0}
          >
            <Image
              unoptimized
              loader={exportableLoader}
              alt=''
              src={Search}
              width={20.5}
              height={20.5}
            />
          </Button>
        </InputRightElement>
      </InputGroup>
    </Flex>
  )
}

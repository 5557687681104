export const initTypesenseAdapter = ({ isFilteredGeneral } : { isFilteredGeneral: boolean }) => {
  return {
    server: {
      nodes: [
        {
          host: process.env.typesenseHostUrl as string,
          port: 443,
          protocol: 'https',
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60,
      apiKey: process.env.typesenseApiKey as string,
    },
    additionalSearchParameters: {
      query_by: 'title',
      filter_by: isFilteredGeneral ? 'manufacturers:!=General' : undefined,
      group_by: 'title',
      group_limit: 1,
    },
  }
}

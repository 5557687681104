export const retailersInfo = [
  {
    retailer: 'tbv',
    title: 'Total by verizon',
    titleFooter: {
      en: '© 2023 Verizon Wireless, Inc.',
      es: '© 2023 Verizon Wireless, Inc.',
    },
    link: {
      facebook: 'https://www.facebook.com/TotalWireless/',
      instagram: 'https://www.instagram.com/totalwireless/',
      twitter: 'https://twitter.com/TotalWireless',
    },
  },
  {
    retailer: 'wfm',
    title: 'Family Mobile',
    titleFooter: {
      en: '© 2023 Family Mobile, Inc.',
      es: '© 2023 Family Mobile, Inc.',
    },
    link: {
      facebook: 'https://www.facebook.com/MyFamilyMobile/',
      instagram: 'https://www.instagram.com/walmartfamilymobile/',
      twitter: 'https://twitter.com/FamilyMobile',
    },
  },
  {
    retailer: 'gosmart',
    title: 'Go smart',
    titleFooter: {
      en: '© 2023 Go smart, Inc.',
      es: '© 2023 Go smart, Inc.',
    },
    link: {
      facebook: 'https://www.facebook.com/GoSmartMobile/',
      instagram: 'http://instagram.com/gosmartmobile/',
      twitter: 'https://twitter.com/GoSmartMobile/',
      youtube: 'https://www.youtube.com/user/GoSmartMobile/',
    },
  },
  {
    retailer: 'net10',
    title: 'Net 10 wireless',
    titleFooter: {
      en: 'Net10 is a registered trademark of TracFone Wireless, Inc., a Verizon company. © 2023 TRACFONE. All other trademarks, service marks, and trade names referenced in this site are the property of their respective owners.',
      es: 'Net10® es una marca registrada de TracFone Wireless, Inc., una empresa de Verizon. ©2023 TRACFONE. Todas las otras marcas registradas, marcas de servicio y nombres comerciales a los que se hace referencia en este sitio web son propiedad de sus respectivos dueños',
    },
    link: {
      facebook: 'https://www.facebook.com/NET10Wireless/',
      instagram: 'https://www.instagram.com/net10_wireless/',
      twitter: 'https://twitter.com/Net10Wireless',
      blog: 'https://blog.net10.com/',
    },
  },
  {
    retailer: 'pageplus',
    title: 'Pageplus',
    titleFooter: {
      en: '© 2023 Pageplus, Inc.',
      es: '© 2023 Pageplus, Inc.',
    },
    link: {
      facebook: 'https://www.facebook.com/OfficialPagePlusCellular/',
      instagram: 'https://www.instagram.com/pageplus/?hl=en',
    },
  },
  {
    retailer: 'simplemobile',
    title: 'Simple Mobile',
    titleFooter: {
      en: '© 2023 Simple Mobile, Inc.',
      es: '© 2023 Simple Mobile, Inc.',
    },
    link: {
      facebook: 'https://www.facebook.com/simplemobile',
      twitter: 'https://twitter.com/simplemobile',
      youtube: 'https://www.youtube.com/simplemobile',
      blog: 'https://blog.simplemobile.com/',
    },
  },
  {
    retailer: 'straighttalk',
    title: 'Straight Talk',
    titleFooter: {
      en: '© 2023 Straight Talk, Inc.',
      es: '© 2023 Straight Talk, Inc.',
    },
    link: {
      facebook: 'https://www.facebook.com/StraightTalkWireless',
      instagram: 'https://www.instagram.com/mystraighttalk/?hl=en',
      twitter: 'https://twitter.com/MyStraightTalk',
      youtube: 'https://www.youtube.com/user/straighttalkvideos',
    },
  },
  {
    retailer: 'tracfone',
    title: 'Tracfone',
    titleFooter: {
      en: '© 2023 Tracfone, Inc.',
      es: '© 2023 Tracfone, Inc.',
    },
    link: {
      facebook: 'https://www.facebook.com/TracfoneWireless',
      instagram: 'https://www.instagram.com/tracfone/',
      twitter: 'https://twitter.com/tfwirelessinc?lang=en',
      youtube: 'https://www.youtube.com/user/tracfonevideos',
    },
  },
  {
    retailer: 'safelink',
    title: 'SafeLink',
    titleFooter: {
      en: '© 2023 SafeLink, Inc.',
      es: '© 2023 SafeLink, Inc.',
    },
    link: {
    },
  },
  {
    retailer: 'tfunbranded',
    title: 'tfunbranded',
    titleFooter: {
      en: '© 2023 tfunbranded, Inc.',
      es: '© 2023 tfunbranded, Inc.',
    },
    link: {
      facebook: 'https://www.facebook.com/TotalWireless/',
      instagram: 'https://www.instagram.com/totalwireless/',
      twitter: 'https://twitter.com/TotalWireless',
    },
  },
]

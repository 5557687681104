import { Box, Button, Flex } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { Configure, connectRefinementList, connectSearchBox, Hits, Index, InstantSearch } from 'react-instantsearch-dom'
import { postAnalytics } from '../../analytics'
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter'
import { initTypesenseAdapter } from '../../lib/typesense-adapter'
import { HitType } from '../../typesense.interface'
import { RefinementListProvided } from 'react-instantsearch-core'
import { useTranslation } from 'next-i18next'

type TypeHitQuick = {
  hit: HitType
  locale: string | string[] | undefined
  handleChangeInputSearch: (value: string) => void
  setSelectedQuestion: (value: string) => void
}

type TypeSearchBox = {
  refine: (value: string) => void
  inputSearch: string
}

interface TypeRefinementList extends RefinementListProvided {
  selectedItem: string[]
}

type PropsSearchHitQuick = {
  handleChangeInputSearch: (value: string) => void
  setSelectedQuestion: (value: string) => void
  inputSearch: string
  selectedManufacturer: string
  selectedModel: string
  indexNameFaq: string
  indexNameTutorial: string
}

const HitQuick = ({ hit, locale, handleChangeInputSearch, setSelectedQuestion }: TypeHitQuick) => {
  const { t } = useTranslation('common')
  const title = (() => {
    if (locale === 'es') {
      const translation = hit.translations.find(
        (item) => item.language_code === 'es-informal',
      )
      if (translation) return translation.title
    }
    return hit.title
  })()

  return (
    <Box key={hit.id} textAlign={'center'}>
      <Button
        tabIndex={0}
        aria-label={`${t('search')} ${title}`}
        variant='searchItem'
        key={hit.id}
        onClick={() => {
          postAnalytics({
            data: {
              event_category: 'search',
              search_term: title,
              page_url: location.href,
              action: 'submit',
            },
            category: 'search',
          })
          handleChangeInputSearch(title)
          setSelectedQuestion(title)
        }}
      >
        {title}
      </Button>
    </Box>
  )
}

const SearchBox = ({
  refine,
  inputSearch,
}: TypeSearchBox) => {
  useEffect(() => {
    refine(inputSearch)
  }, [inputSearch])
  return (<Box />)
}

const RenderRefinementList = ({ refine, selectedItem }: TypeRefinementList) => {
  useEffect(() => {
    refine(selectedItem)
  }, [selectedItem])
  return (<Box />)
}

const CustomSearchBox = connectSearchBox(SearchBox)

const CustomRefinementList = connectRefinementList(RenderRefinementList)

const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter(
  initTypesenseAdapter({
    isFilteredGeneral: false,
  }),
)

export const SearchHitQuick = ({ handleChangeInputSearch, setSelectedQuestion, inputSearch, selectedManufacturer, selectedModel, indexNameFaq, indexNameTutorial }: PropsSearchHitQuick) => {
  const hitsPerPage = 3
  const { query: { locale } } = useRouter()

  return (
    <Box display={inputSearch ? 'block' : 'none'} w={[333, 333, 450, 552]}>
      <InstantSearch
        indexName={indexNameTutorial}
        searchClient={typesenseInstantsearchAdapter.searchClient}
      >
        <Flex display={'none'}>
          <CustomSearchBox inputSearch={inputSearch} />
        </Flex>
        <Flex justify='center' bg='gray.100' mb={2}>
          <Box>
            <Flex gap={10}>
              <Box
                sx={{
                  '.ais-Hits-list': {
                    'list-style-type': 'none',
                  },
                }}
              >
                <Flex gap={4} flexDir={['column', 'column', 'row']}></Flex>
                <Index indexName={indexNameTutorial}>
                  <Configure hitsPerPage={hitsPerPage} />
                  <Hits hitComponent={({ hit }: { hit: HitType }) => HitQuick({
                    hit,
                    locale, handleChangeInputSearch, setSelectedQuestion,
                  })} />
                </Index>
                <Index indexName={indexNameFaq}>
                  <Configure hitsPerPage={hitsPerPage} />
                  <Hits hitComponent={({ hit }: { hit: HitType }) => HitQuick({
                    hit,
                    locale, handleChangeInputSearch, setSelectedQuestion,
                  })} />
                </Index>
              </Box>
            </Flex>
          </Box>
        </Flex>
        {selectedManufacturer && <CustomRefinementList
          attribute='manufacturers'
          selectedItem={[selectedManufacturer]}
        />}
        {selectedModel && <CustomRefinementList
          attribute='products'
          selectedItem={[selectedModel]}
        />}
        {selectedModel && <CustomRefinementList
          attribute='vanity_names'
          selectedItem={[selectedModel]}
        />}
      </InstantSearch>
    </Box>
  )
}

import { useContext } from 'react'
import { customersObj } from '../../customer-config'
import { ConfCtx } from '../context/conf'

export const RecombeeClientConfiguration = () => {
  const RECOMBEE_INSTANCE_ENVIRONMENT = process.env.recombeeInstanceEnvironment as 'dev' || 'prod'
  const { retailer } = useContext(ConfCtx)
  const customer = customersObj[retailer]

  return [
    customer.recombee[RECOMBEE_INSTANCE_ENVIRONMENT].name,
    customer.recombee[RECOMBEE_INSTANCE_ENVIRONMENT].publicToken,
    {
      region: 'us-west',
    },
  ]
}

import React from 'react'
import languageDetector from '../../lib/languageDetector.js'
import { useRouter } from 'next/router'
import { Link } from '@chakra-ui/react'

const LanguageSwitchLink = ({ locale, ...rest }) => {
  const router = useRouter()

  let href = rest.href || router.asPath
  let pName = router.pathname
  Object.keys(router.query).forEach((k) => {
    if (k === 'locale') {
      pName = pName.replace(`[${k}]`, locale)
      return
    }
    if (k === 'phone') {
      const newName = locale === 'es' ? router.query[k] + 'S' : router.query[k].slice(0, -1)
      pName = pName.replace(`[${k}]`, newName)
    }
    if (k !== 'phone') pName = pName.replace(`[${k}]`, router.query[k])
  })
  if (locale) {
    href = rest.href ? `/${locale}${rest.href}/` : `${pName}/`
  }

  return (
    <Link 
      variant='navLink'
      fontWeight='normal'
      href={href}
      onClick={() => languageDetector.cache(locale)}
    >
      {locale === 'en' ? 'English' : 'Español'}
    </Link>
  )
}

export default LanguageSwitchLink

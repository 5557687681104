import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Radio,
  RadioGroup,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useMemo, useState } from 'react'
import { RefinementListProvided } from 'react-instantsearch-core'
import { ProductInfo } from '../../services/kms'

interface PropsRefinementList extends RefinementListProvided {
  attribute: string
  header: string
  refine: (val: string | string[]) => void
  setChangeSelectedPhone?: (val: boolean) => void
  selectedItemChange: (val: string) => void
  setPhoneInfo?: (value: ProductInfo | null) => void
  selectedItem: string
  showMore: boolean
}

export const SearchFilterList = (renderOptions: PropsRefinementList) => {
  const maxItem = 5
  const {
    items,
    attribute,
    showMore,
    header,
    refine,
    setChangeSelectedPhone,
    selectedItemChange,
    selectedItem,
    setPhoneInfo,
  } = renderOptions
  const [isShowMore, setIsShowMore] = useState(false)
  const filterItems = useMemo(
    () => items.filter(({ label }) => label !== ''),
    [items],
  )
  const { t } = useTranslation('common')
  const handleClickReset = () => {
    refine('')
    if (attribute === 'products' || attribute === 'vanity_names') {
      setPhoneInfo!(null)
    }
    selectedItemChange('')
  }

  const handleClickModel = (value: string) => {
    if (selectedItem === value) {
      handleClickReset()
      return
    }
    refine(value)
    if (attribute === 'products' || attribute === 'vanity_names') {
      setChangeSelectedPhone!(true)
    }
    selectedItemChange(value)
  }

  return (
    <Container
      p={0}
      display={items.length > 0 ? 'flex' : 'none'}
      flexDirection={'column'}
    >
      <Heading fontSize={'md'} mb={3}>
        {header}
      </Heading>
      <RadioGroup value={selectedItem} mb={5}>
        {filterItems
          .slice(0, isShowMore || !showMore ? items.length : maxItem)
          .map((item) => (
            <Container
              variant={'searchFilterContainer'}
              key={item.label}
              tabIndex={0}
              aria-label={`${t('selectFilter')} ${item.label}`}
              onKeyPress={({ key }) =>
                key === 'Enter' ? handleClickModel(item.label) : null
              }
            >
              <Radio
                color={'(bot.bg) !important'}
                bg={'white !important'}
                value={item.label}
                onClick={() => handleClickModel(item.label)}
              >
                <Box
                  dangerouslySetInnerHTML={{
                    __html: item.label,
                  }}
                />
              </Radio>
            </Container>
          ))}
      </RadioGroup>
      {showMore && (
        <Flex flexDirection={'column'} gap={2} w={40}>
          {items.length > maxItem && (
            <>
              {isShowMore ? (
                <Button
                  tabIndex={0}
                  aria-label={t('showLess')}
                  variant={'showMore'}
                  onClick={() => setIsShowMore(false)}
                >
                  {t('showLess')}
                </Button>
              ) : (
                <Button
                  tabIndex={0}
                  aria-label={t('showMore')}
                  variant={'showMore'}
                  onClick={() => setIsShowMore(true)}
                >
                  {t('showMore')}
                </Button>
              )}
            </>
          )}
        </Flex>
      )}
    </Container>
  )
}

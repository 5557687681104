import { Box, Container, Link, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import slugify from 'slugify'
import { cropId } from '../../../utils/helpers'
import { ids, postAnalytics } from '../../analytics'
import { RecombeeClientConfiguration } from '../../lib/recombeeClientConfiguration'
import { HitType } from '../../typesense.interface'

type PropsHit = {
  hit: HitType
  locale?: string | string[] | undefined
  selectedModel: string
  selectedVanityName: string
  inputSearch: string
  getPhoneInfo: (value: string) => void
}

export const SearchHit = ({
  hit,
  locale,
  selectedModel,
  selectedVanityName,
  inputSearch,
  getPhoneInfo,
}: PropsHit) => {
  const maxLength = 50
  const isTutorial = hit.hasOwnProperty('steps')
  const clientConfiguration = RecombeeClientConfiguration()
  const translation: { title: string } = (() => {
    if (locale === 'es') {
      const tmp = hit.translations.find(
        (item) => item.language_code === 'es-informal',
      )
      if (tmp && (tmp.title === '' || !tmp.hasOwnProperty('title'))) {
        const esTmp = hit.translations.find(
          (item) => item.language_code === 'es',
        )
        if (esTmp) return esTmp
      }
      if (tmp) return tmp
    }
    return {
      title: hit.title,
    }
  })()

  const title = useMemo(() => {
    if (translation.title) return translation.title.slice(0, maxLength) + '...'
    return ''
  }, [translation])

  const pathBuilder = (el: HitType) => {
    const productReference =
      el.product_references.find((item) => {
        const withoutLastSymbol = el.products[0].slice(0, -1)
        if (locale === 'es') {
          return (
            item.reference_id.includes(withoutLastSymbol) &&
            item.reference_id.endsWith('S')
          )
        }
        return item.reference_id.includes(withoutLastSymbol)
      }) ||
      el.product_references.find((item) => {
        const isEndsS = item.reference_id[item.reference_id.length - 1] === 'S'
        if (isEndsS && locale === 'es') return item.reference_id
        return item.reference_id
      })
    const productsData = el.products_data[0]

    if (!productReference || !productsData) {
      return ''
    }

    const p = {
      brand: productsData.manufacturer.name,
      referenceId: productReference.reference_id,
    }
    if (
      productsData?.name === selectedModel ||
      el.vanity_names[0] === selectedVanityName
    ) {
      getPhoneInfo(p.referenceId)
    }

    if (!isTutorial && p.brand === 'General') {
      return `/${locale}/topics/?topicName=${slugify(hit?.category || '', {
        lower: true,
        strict: true,
      })}&subtopic=${slugify(hit.child_category, {
        lower: true,
        strict: true,
      })}&question=${slugify(hit.title, { lower: true, strict: true })}`
    }

    return `/${locale}/brands/${p.brand}/${p.referenceId}/${isTutorial ? 'tutorial' : 'faq'
    }/${el.serial}`
  }

  const onHandleClickLink = () => {
    if (typeof window === 'undefined' || !window.recombee) return
    if (!isTutorial) {
      new window.recombee.ApiClient(...clientConfiguration).send(
        new window.recombee.AddPurchase(ids?.instId, cropId(hit), {
          cascadeCreate: true,
        }),
        (error: string) => {
          if (error) console.error(error)
        },
      )
      return
    }
  }

  return (
    <Link
      tabIndex={0} 
      _hover={{ textDecoration: 'none' }}
      href={pathBuilder(hit) ?? '/'}
      onClick={onHandleClickLink}
    >
      <Container
        variant={'searchHit'}
        key={hit.id}
        onClick={() => {
          postAnalytics({
            data: {
              event_category: 'search',
              search_term: inputSearch,
              page_url: location.href,
              link_to: `${location.origin}/${pathBuilder(hit)}`,
              action: 'success',
            },
            category: 'search',
          })
        }}
      >
        <Box>
          <Box
            px={5}
            mb={2}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <Text px={5} fontWeight={'bold'}>
            {isTutorial ? 'Tutorial' : 'Faq'}
          </Text>
        </Box>
      </Container>
    </Link>
  )
}

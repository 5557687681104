import { IconsArray } from './IconsArray.interface'
import {
  AudioPlayback,
  BANDTechnology,
  Clock,
  DefaultIcon,
  DeviceWeight,
  DiagonalSize,
  DownloadSpeed,
  Esim,
  IncludedBattery,
  InternalMemory,
  Os,
  PrimaryCamera,
  ProcessorSpeed,
  ScreenResolution,
  SecondaryCamera,
  SizeDisplay,
  StandbyTime,
  StreamingVideo,
  TalkTime,
  UploadSpeed,
  UsageTime,
  VideoPlayback,
  WaterproofRating,
  Wifi,
} from '../styles/components/customIcons'

export const getSpecificationsIcons = (type: string) => {
  switch (type) {
    case 'ScreenResolution':
      return <ScreenResolution />
    case 'AudioPlayback':
      return <AudioPlayback />
    case 'DeviceWeight':
      return <DeviceWeight />
    case 'DiagonalSize':
      return <DiagonalSize />
    case 'DownloadSpeed':
      return <DownloadSpeed />
    case 'IncludedBattery':
      return <IncludedBattery />
    case 'InternalMemory':
      return <InternalMemory />
    case 'PrimaryCamera':
      return <PrimaryCamera />
    case 'ProcessorSpeed':
      return <ProcessorSpeed />
    case 'SecondaryCamera':
      return <SecondaryCamera />
    case 'SizeDisplay':
      return <SizeDisplay />
    case 'StandbyTime':
      return <StandbyTime />
    case 'StreamingVideo':
      return <StreamingVideo />
    case 'TalkTime':
      return <TalkTime />
    case 'UploadSpeed':
      return <UploadSpeed />
    case 'UsageTime':
      return <UsageTime />
    case 'VideoPlayback':
      return <VideoPlayback />
    case 'WaterproofRating':
      return <WaterproofRating />
    case 'BrowsingTime':
      return <Clock />
    case 'InternetTime':
      return <Wifi />
    case 'OS':
      return <Os />
    case 'BANDTechnology':
      return <BANDTechnology />
    case 'Esim':
      return <Esim />
    default:
      return <DefaultIcon />
  }
}

export const iconsArray: IconsArray[] =
  [
    {
      variants: [
        'device weight',
        'weight',
        'peso',
      ],
      src: 'DeviceWeight',
    },
    {
      variants: [
        'screen resolution',
        'screen',
        'new screen resolution',
        'resolución de la pantalla',
        'pantalla',
      ],
      src: 'ScreenResolution',
    },
    {
      variants: [
        'diagonal size',
        'size (diagonal)',
        'screen size',
        'display size (diagonal)',
        'display',
        'tamaño de la pantalla (diagonal)',
        'tamaño de la pantalla',
      ],
      src: 'DiagonalSize',
    },
    {
      variants: [
        'included battery',
        'internal battery',
        'included battery (non-removable)',
        'battery',
        'built-in battery',
        'batería incluida',
        'batería',
        'duración de la batería',
        'included battery (typical)',
      ],
      src: 'IncludedBattery',
    },
    {
      variants: [
        'waterproof rating',
      ],
      src: 'WaterproofRating',
    },
    {
      variants: [
        'talk time',
        '4g talk time',
        'talk time / standby',
        'tiempo de llamadas',
      ],
      src: 'TalkTime',
    },
    {
      variants: [
        'standby',
        '4g standby time',
        'standby time',
        'tiempo de espera de la batería',
        'tiempo de espera',
        'Tiempo de Llamadas / Tiempo de Espera',
      ],
      src: 'StandbyTime',
    },
    {
      variants: [
        'internal memory',
        'storage',
        'memory',
      ],
      src: 'InternalMemory',
    },
    {
      variants: [
        'primary camera',
        'main camera',
        'camera',
        'Cámara Frontal',
      ],
      src: 'PrimaryCamera',
    },
    {
      variants: [
        'secondary camera',
        'Cámara Trasera',
      ],
      src: 'SecondaryCamera',
    },
    {
      variants: [
        'size display',
        'Device size',
        'display size',
        'size (h x w x d)',
        'size',
        'dimensions',
        'device size',
        'screen size/resolution | internal',
        'screen size/resolution | external',
        'tamaño de pantalla / resolución | interno',
        'tamaño de pantalla / resolución | externo',
        'size | open',
        'size | closed',
        'tamaño | abierto',
        'tamaño | cerrado',
        'tamaño',
        'tamaño del dispositivo',
        'tamaño (diagonal)',
      ],
      src: 'SizeDisplay',
    },
    {
      variants: [
        'video playback',
        'reproducción de video',

      ],
      src: 'VideoPlayback',
    },
    {
      variants: [
        'streaming video',
        'streaming de vídeo',
      ],
      src: 'StreamingVideo',
    },
    {
      variants: [
        'audio playback',
        'reproducción de audio',
      ],
      src: 'AudioPlayback',
    },
    {
      variants: [
        'usage time',
      ],
      src: 'UsageTime',
    },
    {
      variants: [
        'processor speed',
      ],
      src: 'ProcessorSpeed',
    },
    {
      variants: [
        'download speed',
        'speed',
      ],
      src: 'DownloadSpeed',
    },
    {
      variants: [
        'upload speed',
      ],
      src: 'UploadSpeed',
    },
    {
      variants: [
        'Browsing time',
      ],
      src: 'BrowsingTime',
    },
    {
      variants: [
        'Internet time',
      ],
      src: 'InternetTime',
    },
    {
      variants: [
        'OS',
      ],
      src: 'OS',
    },
    {
      variants: [
        'BAND (FREQUENCY) Technology',
      ],
      src: 'BANDTechnology',
    },
    {
      variants: [
        'eSIM',
      ],
      src: 'Esim',
    },
  ]
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Input,
  Text,
  useTheme,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { ChangeEvent, useContext, useState } from 'react'
import { ConfCtx } from '../../context/conf'
import { exportableLoader } from '../../image-loader'
import { theme } from '../../styles/theme'

const NotValidEmail = ({ onClose }: { onClose: () => void }) => (
  <Container variant={'footer_email_notification'}>
    Your Email is required.
    <Button onClick={onClose} color={'red'}>
      x
    </Button>
  </Container>
)

export const SignForm = () => {
  const { retailer } = useContext(ConfCtx)
  const themes = { ...theme(retailer), ...useTheme() }
  const {
    query: { locale },
  } = useRouter()
  const { t } = useTranslation('footer')
  const [email, setEmail] = useState('')
  const [isEmail, setIsEmail] = useState(false)
  const [isNotValidEmail, setisNotValidEmail] = useState(false)
  const { footerStyles } = themes

  const validationEmail = (value: string) => {
    const validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return value.match(validEmailRegex)
  }

  const onInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => setEmail(value)

  const onEnterClick = () => {
    if (validationEmail(email)) {
      setIsEmail(true)
    } else {
      setisNotValidEmail(true)
      setTimeout(() => setisNotValidEmail(false), 3000)
    }
  }

  const onCloseNotificationClick = () => {
    setisNotValidEmail(false)
  }

  return (
    <Box position={'relative'} maxW={'md'} h={60}>
      {isNotValidEmail && <NotValidEmail onClose={onCloseNotificationClick} />}
      {locale === 'en' &&
        (isEmail ? (
          <Box>
            <Heading
              variant='footerTitleSignForm'
              fontWeight={'extrabold'}
              fontSize={22}
              tabIndex={0}
            >
              THANK YOU!
            </Heading>
            <Text tabIndex={0} w={72} fontSize={'sm'} {...footerStyles.signUpThanksStyle}>
              {t(`thank_for_sign.${retailer}`)}
            </Text>
          </Box>
        ) : (
          <Box>
            <Heading as='h3' variant='footerTitleSignForm' tabIndex={0}>
              SIGN UP
            </Heading>
            <Text tabIndex={0}>{t(`sign_up.sub_title.${retailer}`)}</Text>
            <Box>
              <Flex my='21'>
                <Input
                  {...footerStyles.signUpInput}
                  tabIndex={0}
                  type={'email'}
                  value={email}
                  onChange={onInputChange}
                  onKeyPress={({ code }) => code === 'Enter' && onEnterClick()}
                />
                <Button
                  tabIndex={0}
                  aria-label={'Send email address'}
                  {...footerStyles.signUpButton.styles}
                  onClick={onEnterClick}
                >
                  {footerStyles.signUpButton.button.image && (
                    <Image
                      {...footerStyles.signUpButton.button.image}
                      unoptimized
                      loader={exportableLoader}
                      alt=''
                    />
                  )}
                  {footerStyles.signUpButton.button.text}
                </Button>
              </Flex>
              <Text mb={4} fontSize={'11'} tabIndex={0}>
                {footerStyles.signUpAgree.exclusionsText}
              </Text>
              <Text tabIndex={0} mb={12} fontSize={'11'} {...footerStyles.signUpAgree.styes}>
                {t(`agree.${retailer}`)}
              </Text>
            </Box>
          </Box>
        ))}
    </Box>
  )
}

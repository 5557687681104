/* eslint-disable import/no-extraneous-dependencies */
import { groupBy, uniqBy } from 'lodash'
import { type RequestInfo, type RequestInit, type Response } from 'node-fetch'

export type Fetch = (url: RequestInfo, init?: RequestInit) => Promise<Response>

type KmsApiClientConf = {
  base: string
}

type ValueType = string | number | boolean | undefined
type KmsApiClientReq = {
  path: string
  method: 'get' | 'post' | 'put' | 'patch'
  query?: Record<string, ValueType>
}

const kmsApiClient =
  ({ base }: KmsApiClientConf) =>
    (fetch: Fetch) =>
      ({ path, method, query }: KmsApiClientReq): Promise<unknown> => {
        const q =
          query &&
          Object.entries(query).reduce(
            (acc, [k, v]) =>
              v === undefined
                ? acc
                : acc === ''
                  ? `?${k}=${v.toString()}`
                  : `${acc}&${k}=${v.toString()}`,
            '',
          )
        const url = `${base}/public/v1${path}${q ? q : ''}`
        //console.log(url)
        return fetch(
          url,
          { method },
          // TODO: check response code before parsing JSON.
          // TODO: reject on non 200 ?
        ).then(a => a.json())
      }

type KmsRes<T> = {
  data: T
}

type Manufacturer = { name: string; id: string }
const isManufacturer = (a: unknown): a is Manufacturer =>
  typeof a === 'object' &&
  typeof (a as Manufacturer).name === 'string' &&
  typeof (a as Manufacturer).id === 'string'

type Reference = {
  // id: string,
  serial: number
  reference_id: string
  vanity_name: string | null
  product: {
    name: string
    manufacturer: {
      name: string
    }
  }
  documents: {
    main_image: string
  }
  customers: Array<{
    slug: string
  }>
}

const isReference = (a: unknown): a is Reference =>
  typeof a === 'object' &&
  typeof (a as Reference).serial === 'number' &&
  typeof (a as Reference).reference_id === 'string' &&
  (typeof (a as Reference).vanity_name === 'string' ||
    (a as Reference).vanity_name === null) &&
  Array.isArray((a as Reference).customers) &&
  (a as Reference).customers.every((c) => typeof c.slug === 'string')

export type ProductInfo = Reference & {
  features: Array<{
    value: string
  }>
  specs: Array<{
    name: string
    value: string
  }>
  accessories: unknown[]
}

const isProductInfo = (a: unknown): a is ProductInfo =>
  isReference(a) &&
  Array.isArray((a as ProductInfo).features) &&
  (a as ProductInfo).features.every(
    (feature) => typeof feature.value === 'string',
  ) &&
  Array.isArray((a as ProductInfo).specs) &&
  (a as ProductInfo).specs.every(
    (spec) => typeof spec.name === 'string' && typeof spec.value === 'string',
  )

export type Faq = {
  id: string
  slug?: string
  title: string
  answer: string
  child_category: {
    id: string
    name: string
    output_order: number
    category: {
      id: string
      name: string
      output_order: number
      icon: string
    }
  }
  serial: number
  faqsProducts: Array<{
    product: {
      name: string
      serial: number
      references: Array<{
        reference_id: string
        vanity_name: string
        serial: number
      }>
      manufacturer: {
        name: string
      }
    }
  }>
}

type Tutorial = {
  id: string
  title: string
  slug: string
  visibility: string
  serial: number
  type: string
  categories: Array<{
    id: string
    name: string
    output_order: number
  }>
  /* customers: Array<{
    name: string
  }> */
  product: {
    name: string
    serial: number
    manufacturer: {
      name: string
      id: string
      image_url: string
    }
    references: Array<{
      id: string
      reference_id: string
      vanity_name: string
      reference_type: string
      serial: number
    }>
  }
}

export type ActionT =
  | 'click'
  | 'swipe_up'
  | 'swipe_down'
  | 'swipe_right'
  | 'swipe_left'
  | 'automate'

export type Faceplate = {
  id: string
  is_behind: boolean
  portrait_image: string
  portrait_x: number
  portrait_y: number
  portrait_width: number
  portrait_height: number
  landscape_image: string
  landscape_x: number
  landscape_y: number
  landscape_width: number
  landscape_height: number
}

export type TutorialDetails = {
  title: string
  type: 'Interactive' | 'HTML5'
  slug: string
  serial: string
  
  categories: {
    name: string,
  }[]

  product: {
    name: string
    serial: number
    faceplate: Faceplate
  }

  steps: Array<{
    is_substep: boolean
    delay: number
    text: string
    order: number
    sub_order: number
    file_path: string
    orientation: 'portrait' | 'landscape'
    action: {
      x: number
      y: number
      type: ActionT
      on_screen: boolean
    }
    animation: Array<unknown>
    translations: Array<{
      language: { code: string }
      text: string
    }>
  }>

  translations: Array<{
    language: {
      code: string
    }
    title: string
  }>
}

export const kmsApi = (conf: KmsApiClientConf) => (fetch: Fetch) => {
  const client = kmsApiClient(conf)(fetch)
  return {
    manufacturers: async ({ customer }: { customer: string }) => {
      const res = await client({
        path: '/manufacturers',
        method: 'get',
        query: { customer },
      })
      if (!res || !(res as KmsRes<unknown>).data) {
        throw new Error('not a Kms response, missing `data` prop')
      }
      const data = (res as KmsRes<unknown>).data
      if (
        data &&
        Array.isArray(data) &&
        data.map(isManufacturer).reduce((acc, curr) => acc && curr, true)
      )
        return data.filter(
          (m) => m.name !== 'General' && m.name,
        ) as Manufacturer[]
      throw new Error('not a manufacturer')
    },
    products: async (query: {
      customer: string
      language?: string
      manufacturerId?: string
    }) => {
      const res = await client({
        path: '/product',
        method: 'get',
        // BRIAN TO LOOK
        query: {
          ...query,
          language: query.language === 'es' ? 'es-informal' : query.language,
        },
      })

      if (!res || !(res as KmsRes<unknown>).data) {
        throw new Error('not a Kms response, missing `data` prop')
      }
      const data = (res as KmsRes<unknown>).data
      if (
        data &&
        Array.isArray(data) &&
        data.map(isReference).reduce((acc, curr) => acc && curr, true)
      ) {
        // remove multiple references for the same product
        const uniqData = uniqBy(data, 'product.name')
        return uniqData as Reference[]
      }

      throw new Error('not a product')
    },
    productInfo: async ({
      referenceId,
      language,
      customer,
    }: {
      customer: string
      referenceId: string
      language: string
    }) => {
      const res = await client({
        path: `/product/by-reference/${referenceId.trim()}`,
        method: 'get',
        query: {
          customer,
          language: language === 'es' ? 'es-informal' : language,
        },
      })

      // TODO: standart response on api
      const data = res
      if (isProductInfo(data)) return data as ProductInfo

      throw new Error('not a ProductInfo')
    },
    productInfoBySerial: async ({
      serial,
      language,
      customer,
    }: {
      customer: string
      serial: number
      language: string
    }) => {
      const res = await client({
        path: `/product/by-serial/${serial}`,
        method: 'get',
        query: {
          customer,
          language: language === 'es' ? 'es-informal' : language,
        },
      })

      // TODO: standart response on api using ZOD
      const data = res
      //if (isProductInfo(data))
      return data as ProductInfo

      //throw new Error('not a ProductInfo')
    },
    faqs:
      ({
        referenceId,
        language,
        customer,
      }: {
        customer: string
        referenceId?: string
        language: string
      }) =>
        async ({ take, skip }: { take: number; skip: number }) => {
          const res = await client({
            path: '/faq',
            method: 'get',
            query: {
              product_reference_id: referenceId,
              customer,
              language: language === 'es' ? 'es-informal' : language,
              take,
              skip,
            },
          })

          // TODO: standart response on api
          // TODO: validation
          return res as { count: number, data: Faq[] }

        },
    faqBySerial: async ({
      serial,
      language,
      customer,
    }: {
      customer: string
      serial: number
      language: string
    }) => {
      const res = await client({
        path: `/faq/by-serial/${serial}`,
        method: 'get',
        query: {
          customer,
          language: language === 'es' ? 'es-informal' : language,
        },
      })

      // TODO: standart response on api
      // TODO: validation
      return res as Faq[]
    },
    faqBySlug: async ({
      slug,
      language,
      customer,
    }: {
      customer: string
      slug: string
      language: string
    }) => {
      try {
        const res = await client({
          path: `/faq/by-slug/${slug}`,
          method: 'get',
          query: {
            customer,
            language: language === 'es' ? 'es-informal' : language,
          },
        }) as Faq
        if (!res.answer) throw new Error('not a Kms response, missing `faq.answer` prop')
        return res
      } catch (error) {
        throw new Error(error as string)
      }
    },
    tutorials:
      ({
        referenceId,
        language,
        customer,
      }: {
        referenceId?: string
        language: string
        customer: string
      }) =>
        async ({ take, skip }: { take: number; skip: number }) => {
          const res = await client({
            path: '/tutorial',
            method: 'get',
            query: {
              product_reference_id: referenceId,
              customer,
              language: language === 'es' ? 'es-informal' : language,
              take,
              skip,
            },
          })
          // TODO: standart response on api
          // TODO: validation
          return res as { count: number; data: Tutorial[] }
        },
    categoryOrderById: ({ id }: { id: string }) => async () => {
      const res = await client({
        path: `/content/order/${id}`,
        method: 'get',
      })
      return res as { data: { id: string, ordering_container_id: string, content_order: string[] } }
    },
    tutorialBySerial: async ({
      serial,
      language,
      customer,
    }: {
      customer: string
      serial: number
      language: string
    }) => {
      const res = await client({
        path: `/tutorial/by-serial/${serial}`,
        method: 'get',
        query: {
          customer,
          language: language === 'es' ? 'es-informal' : language,
          skip: 0,
          take: 1,
        },
      })

      // TODO: standart response on api
      // TODO: validation
      return res as TutorialDetails
    },
  }
}

type Skippable = { take: number; skip: number }
type Countable = { count: number }

export const toGenerator = <B extends Countable>(
  a: (a: Skippable) => Promise<B>,
) => {
  return async function* gen({ skip, take }: Skippable) {
    const res = await a({ skip, take })
    const count = res.count
    yield res

    while (skip <= count) {
      skip += take
      yield await a({ skip, take })
    }
  }
}
export const foldToCategories = (faqs: Faq[]) => {
  const catGroups = Object.entries(
    groupBy(faqs, (a) => a.child_category.category.name),
  ).map(([catName, children]) => {
    const order = faqs.find(
      (el) => el.child_category.category.name === catName,
    )
    return {
      name: catName,
      children,
      id: order ? order.child_category.category.id : '',
      output_order: order ? order.child_category.category.output_order : Infinity,
    }
  })

  return catGroups.map((c) => {
    const subcats = groupBy(c.children, (a) => a.child_category.name)
    return {
      id: c.id,
      name: c.name,
      output_order: c.output_order,
      children: Object.entries(subcats).map(([scName, children]) => {
        const order = c.children.find(
          (el) => el.child_category.name === scName,
        )
        return {
          name: scName,
          id: order ? order.child_category.id : '',
          output_order: order ? order.child_category.output_order : Infinity,
          children: children.map((f) => ({
            name: f.title,
            pk: f.serial,
            id: f.id,
          })),
        }
      }),
    }
  })
}
export const foldToTutorialCategories = (tutorials: Tutorial[]) => {
  const flattenCategories = tutorials.map((tutorial) => tutorial.categories.map(c => ({
    ...tutorial,
    categories: c,
  }))).flat()
  const catGroups = Object.entries(
    groupBy(flattenCategories, (a) => a.categories.name),
  ).map(([catName, children]) => {
    const order = flattenCategories.find(
      (el) => el.categories.name === catName,
    )
    return {
      name: catName,
      children,
      output_order: order ? order.categories.output_order : Infinity,
      id: children[0].categories.id,
    }
  })

  return catGroups.map((c) => {
    return {
      id: c.id,
      name: c.name,
      output_order: c.output_order,
      children: c.children.map((child) => ({
        id: child.id,
        name: child.title,
        pk: child.serial,
      })),
    }
  })
}

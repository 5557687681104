import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  keyframes,
  Link,
  Text,
  usePrefersReducedMotion,
  useTheme,
} from '@chakra-ui/react'
import Image from 'next/image'
import nextI18nextConfig from '../../../next-i18next.config.js'
import LanguageSwitchLink from '../languageSwitchLink/languageSwitchLink.jsx'
import { HeaderList } from '../header/header'
import LocaleSolid from '/public/assets/locale_solid.svg'
import { exportableLoader } from '../../image-loader'
import { useContext } from 'react'
import { ConfCtx } from '../../context/conf'
import { customersObj } from '../../../customer-config'
import { theme } from '../../styles/theme'
import { ShopAccordion } from '../shopList/shopList'
import { ShopListStraighttalk } from '../shopList/ShopListStraighttalk'
import { Item } from '../../content/headerData.interface.js'

interface MobileHeaderProps {
  headerList: HeaderList
  currentLocale: string
  isExpandedMenu: boolean
}

const ShopList = ({
  retailer,
  ...props
}: {
  retailer: string
  headerData: Item[]
  index: number
}) => {
  switch (retailer) {
    case 'straighttalk':
      return (
        <ShopListStraighttalk
          headerData={props.headerData}
          isMobile={true}
          index={props.index}
        />
      )
    default:
      return <ShopAccordion headerData={props.headerData} isMobile={true} />
  }
}

export const MobileHeaderSliding = ({
  headerList,
  currentLocale,
  isExpandedMenu,
}: MobileHeaderProps) => {
  const { retailer } = useContext(ConfCtx)
  const themes: any = { ...theme(retailer), ...useTheme() }
  const { headerStyles } = themes
  const styleConfig = customersObj[retailer]?.headerConfig
  const prefersReducedMotion = usePrefersReducedMotion()
  const occurrence = keyframes`
    from {
      transform: translate3d(-100%,0,0);
      visibility: visible;
      }
      to {
        transform: translate3d(0%,0,0);
        transform: translateZ(0);
      }`
  const animationOccurrence = prefersReducedMotion
    ? undefined
    : `${occurrence} 0.9s linear`

  return (
    <>
      {headerList.item.map((item, i) =>
        item.children.length ? (
          <Accordion allowToggle h={'auto'} key={i}>
            <AccordionItem border='none' textColor={'black'}>
              <AccordionButton
                tabIndex={0}
                animation={isExpandedMenu ? undefined : animationOccurrence}
                _expanded={{
                  bg: 'headerHelmet.bg',
                  color: 'headerHelmet.text',
                }}
                _focus={{
                  bg: 'none',
                }}
                color={'text.header'}
                fontWeight={'bold'}
                fontSize={'sm'}
                justifyContent={'space-between'}
                py={6}
                pl={
                  !styleConfig?.separatedLogo
                    ? 10
                    : 5
                }
                textTransform={'uppercase'}
                sx={{
                  animationDelay: `0.${i}s`,
                }}
                {...headerStyles?.headerMobile?.accordionButton}
              >
                <Text>{item.title}</Text>
                <AccordionIcon />
              </AccordionButton>
              {styleConfig?.specialShopList ? (
                <AccordionPanel>
                  <ShopList
                    retailer={retailer}
                    headerData={headerList.item}
                    index={i}
                  />
                </AccordionPanel>
              ) : (
                <AccordionPanel>
                  {i === 0 ? (
                    <ShopList
                      retailer={retailer}
                      headerData={headerList.item}
                      index={i}
                    />
                  ) : item.flyoutButtonLabel ? (
                    <>
                      <Flex
                        alignItems={'center'}
                        justify={'space-around'}
                        flexDirection={'column'}
                        mt={3}
                        pb={12}
                        borderBottom={'1px solid'}
                        borderColor={'gray.200'}
                      >
                        <Heading
                          fontSize={'md'}
                          w={52}
                          textAlign={'center'}
                          color={'text.header'}
                          tabIndex={0}
                        >
                          {item.flyoutHeader}
                        </Heading>
                        <Flex
                          flexDirection={'column'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          gap={3}
                        >
                          <Link href={item.url}>
                            <Button variant={'headerAccordionFlyout'} mt={6} tabIndex={0}>
                              {item.flyoutButtonLabel.toUpperCase()}
                            </Button>
                          </Link>
                        </Flex>
                      </Flex>
                      <Flex
                        alignItems={'flex-start'}
                        justify={'space-around'}
                        flexDirection={'row'}
                        mt={3}
                      >
                        <Heading
                          as={'h3'}
                          variant={'shopListSmall'}
                          fontSize='sm'
                          w={24}
                          justifyContent={'space-around'}
                          tabIndex={0}
                        >
                          {item.title}
                        </Heading>
                        <Flex
                          flexDirection={'column'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          gap={3}
                        >
                          {item.children.map((child, index) => (
                            <Link
                              key={index}
                              href={child.url}
                              fontSize={'xs'}
                              color={'black.400'}
                              tabIndex={0}
                            >
                              {child.title}
                            </Link>
                          ))}
                        </Flex>
                      </Flex>
                    </>
                  ) : (
                    <Flex
                      alignItems={'flex-start'}
                      justify={'space-around'}
                      flexDirection={'row'}
                      mt={3}
                    >
                      <Heading
                        as={'h3'}
                        variant={'shopListSmall'}
                        fontSize='sm'
                        w={24}
                        justifyContent={'space-around'}
                        tabIndex={0}
                      >
                        {item.title}
                      </Heading>
                      <Flex
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        gap={3}
                      >
                        {item.children.map((child, index) => (
                          <Link
                            key={index}
                            href={child.url}
                            fontSize={'xs'}
                            color={'black.400'}
                            tabIndex={0}
                          >
                            {child.title}
                          </Link>
                        ))}
                      </Flex>
                    </Flex>
                  )}
                </AccordionPanel>
              )}
            </AccordionItem>
          </Accordion>
        ) : (
          <Container
            display={'flex'}
            flexDirection={'column'}
            p={0}
            animation={isExpandedMenu ? undefined : animationOccurrence}
            sx={{
              animationDelay: `0.${i}s`,
            }}
            textTransform={'uppercase'}
            fontSize={'sm'}
            {...headerStyles?.headerMobile?.containerLink}
            key={i}
            borderBottom='1px solid'
            borderColor='gray.200'
          >
            <Text
              p={4}
              textAlign='left' 
              variant='navText'
              pl={
                !styleConfig?.separatedLogo
                  ? 10
                  : 5
              }
              >
              <Link
                aria-label={item.title}
                href={item.url}
                color={'text.header'}
              >
                {item.title}
              </Link>
            </Text>
          </Container>
        ),
      )}
      {styleConfig?.mobile?.navigationLinks ? (
        <Container
          variant={'mobileHeader'}
          px={0}
          animation={isExpandedMenu ? undefined : animationOccurrence}
        >
          {headerList.navigationLinks?.map((link, index) => (
            <Link
              href={link.linkUrl}
              target='_blank'
              aria-label={link.linkAltText}
              key={index}
              variant='mobileNavLink'
              py={4}
              pl={10}
              color={'text.header'}
              textTransform={'none'}
            >
              {link.linkText}
            </Link>
          ))}
        </Container>
      ) : (
        headerList.links.map((item, i) => (
          <Container
            key={i}
            display={'flex'}
            flexDirection={'column'}
            borderBottom={'1px solid'}
            borderColor={'gray.200'}
            p={0}
            animation={isExpandedMenu ? undefined : animationOccurrence}
            sx={{
              animationDelay: `0.${i}s`,
            }}
          >
            <Text p={5} key={i} textAlign='left' variant='navText'>
              <Link
                aria-label={item.linkAltText}
                href={item.linkUrl}
                fontSize={'sm'}
                color={'text.header'}
                textTransform={'uppercase'}
              >
                {item.linkText}
              </Link>
            </Text>
          </Container>
        ))
      )}
      <Flex
        py={5}
        pl={
          !styleConfig?.separatedLogo
            ? 10
            : 3
        }
        flexDir='column'
        color={'text.header'}
        gap={4}
        fontWeight={'bold'}
        fontSize={11}
        textTransform={'uppercase'}
        {...headerStyles?.headerMobile?.containerZip}
      >
        <Box
          display='flex'
          alignItems={'center'}
          {...headerStyles?.iconFilter}
          animation={isExpandedMenu ? undefined : animationOccurrence}
        >
          <Image
            unoptimized
            loader={exportableLoader}
            alt='locale'
            src={LocaleSolid}
          />
          <Text alignItems={'center'}>
            {nextI18nextConfig.i18n.locales.map((locale) => {
              if (locale === currentLocale) return null
              return <LanguageSwitchLink locale={locale} key={locale} />
            })}
          </Text>
        </Box>
        {styleConfig?.mobile?.navigationLinks &&
          headerList.links.map((el, i) => (
            <Text
              fontWeight={'bold'}
              key={i}
              fontSize={'xs'}
              animation={isExpandedMenu ? undefined : animationOccurrence}
              sx={{
                animationDelay: `0.${i}s`,
              }}
            >
              <a
                aria-label={el.linkAltText}
                href={`${customersObj[retailer].headerExternalLinkBase}/${el.linkUrl}`}
                rel={el.doNotFollowLink}
              >
                {el.linkText}
              </a>
            </Text>
          ))}
      </Flex>
    </>
  )
}

import { Heading, ListItem, UnorderedList, Text, Flex } from '@chakra-ui/react'
import Link from 'next/link'
import React, { useContext } from 'react'
import { FooterListInterface } from '../../content/footerListInterface'
import { ConfCtx } from '../../context/conf'
import Image from 'next/image'
import { exportableLoader } from '../../image-loader'

export const FooterList = ({ title, subItems }: FooterListInterface) => {
  const { retailer } = useContext(ConfCtx)
  return (
    <UnorderedList ml={0}>
      {
        title && (
          <ListItem listStyleType='none'>
            {retailer === 'straighttalk' || retailer === 'tracfone' ? (
              <Heading as='h3' variant={'footerText_small'} tabIndex={0}>
                {title}
              </Heading>
            ) : (
              <Heading
                as='h3'
                fontSize={[20, 20, 20, 24]}
                variant={'footerSubTitles'}
                tabIndex={0}
              >
                {title}
              </Heading>
            )}
          </ListItem>
        )
      }
      {subItems.map((el, i) => (
        <ListItem
          _hover={{ color: 'gray.600', textDecoration: 'underline' }}
          key={i}
          listStyleType='none'
          my={0}
          pb={3}
          fontSize={'xs'}
        >
          {el.linkUrl ? (
            <Link href={el.linkUrl} passHref>
              <a
                color='black'
                target={el.withoutBlank ? undefined : '_blank'}
                rel='noreferrer'
                onClick={el.onClick ?? undefined}
              >
                <Flex gap={1}>
                  {
                    el.linkIcon && (
                      <Image
                        src={el.linkIcon}
                        loader={exportableLoader}
                        width={30}
                        height={14}
                        alt={el.linkText}
                      />
                    )
                  }
                  <Text  tabIndex={0}>
                    {el.linkText}
                  </Text>
                </Flex>
              </a>
            </Link>
          ) : (
            <Text cursor='pointer' onClick={el.onClick ?? undefined}  tabIndex={0}>
              {el.linkText}
            </Text>
          )}
        </ListItem>
      ))}
    </UnorderedList>
  )
}
